import { FC, memo, Dispatch, SetStateAction } from 'react';
import { createPortal } from 'react-dom';

import { Button, Dialog } from 'components';
import { ButtonStyle, ButtonType } from 'enum';

interface RevokeConsentDialogProps {
  setConsentDialog: Dispatch<SetStateAction<boolean>>;
  revokeConsent: () => void;
}

export const RevokeConsentDialog: FC<RevokeConsentDialogProps> = memo(
  ({ setConsentDialog, revokeConsent }) => {
    return createPortal(
      <Dialog
        title="Revoke Consent"
        subTitle="Are you sure you want to revoke your consent?"
      >
        <div className="py-3">
          <Button
            onClick={() => revokeConsent()}
            className={`${ButtonStyle.PRIMARY} w-100 text-white`}
            type={ButtonType.BUTTON}
            name="yes"
          >
            Yes
          </Button>
        </div>
        <div className="py-2">
          <Button
            className={`${ButtonStyle.OUTLINEPRIMARY} w-100`}
            type={ButtonType.BUTTON}
            name="cancel"
            onClick={() => setConsentDialog(false)}
          >
            Cancel
          </Button>
        </div>
      </Dialog>,
      document.querySelector('#root') as Element,
    );
  },
);
