import {
  memo,
  FC,
  useState,
  useMemo,
  BaseSyntheticEvent,
  useCallback,
  useEffect,
} from 'react';

import { Column } from 'interfaces';
import { Icon, SelectInput, Button } from 'components';
import { ButtonStyle, ButtonType, IconSize } from 'enum';
import noDataIcon from '../../assets/images/no-data-icon.svg';
import { Message } from '../../constants';

import styles from './DataTable.module.scss';

interface DataSource {
  [key: string]: any;
}

interface DataTableProps {
  columns: Column[];
  data: DataSource[];
  withPagination?: boolean;
  onRefresh: () => void;
  onDownload?: () => void;
  loading?: boolean;
  className?: string;
}

enum Direction {
  Left = 'left',
  Right = 'right',
}

enum PageType {
  First = 'first',
  Last = 'last',
}

const OPTIONS_ITEMS_PER_PAGE = [
  {
    name: '5',
    value: 5,
  },
  {
    name: '10',
    value: 10,
  },
];

const DEFAULT_ITEMS_PER_PAGE = 5;

export const DataTable: FC<DataTableProps> = memo(
  ({
    columns,
    data,
    withPagination,
    onRefresh,
    onDownload,
    loading,
    className = '',
  }) => {
    const [pagination, setPagination] = useState({
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
      currentPage: 1,
      totalPages: Math.ceil(data.length / DEFAULT_ITEMS_PER_PAGE),
    });

    const onUpdateState = (currentPage: number) => {
      if (currentPage < 1 || currentPage > pagination.totalPages) {
        return;
      }
      setPagination((currentState) => {
        return {
          ...currentState,
          currentPage: currentPage,
        };
      });
    };

    const onChangePage = (direction: Direction, pageType: PageType) => {
      if (direction === Direction.Right) {
        if (pageType === PageType.Last) {
          onUpdateState(Math.ceil(data.length / pagination.itemsPerPage));
        } else {
          onUpdateState(pagination.currentPage + 1);
        }
      } else {
        if (pageType === PageType.First) {
          onUpdateState(1);
        } else {
          onUpdateState(pagination.currentPage - 1);
        }
      }
    };

    const onChangeItemsPerPage = useCallback((event: BaseSyntheticEvent) => {
      setPagination((currentState) => {
        return {
          ...currentState,
          currentPage: 1,
          itemsPerPage: event.target.value,
          totalPages: Math.ceil(data.length / event.target.value),
        };
      });
    }, []);

    const headerPagination = useMemo(() => {
      return (
        <div className="p-3 clearfix">
          {(typeof withPagination !== 'boolean' || withPagination) &&
            data.length > 0 && (
              <div
                className={`${styles.headerPagination} header-pagination font-weight-medium d-inline-block`}
              >
                <div className="d-inline-block pe-3">
                  <SelectInput
                    className={styles.select}
                    onChange={onChangeItemsPerPage}
                    name="Entries"
                    label=""
                    selectedValue={pagination.itemsPerPage}
                    options={OPTIONS_ITEMS_PER_PAGE}
                  />
                </div>
                <div className="d-inline-block">
                  <p className={`${styles.entriesTitle} font-weight-medium`}>
                    Entries
                  </p>
                </div>
              </div>
            )}
          <div className="d-inline-block float-end">
            <Button
              disabled={loading}
              name="refresh"
              onClick={onRefresh}
              type={ButtonType.BUTTON}
              className={`${ButtonStyle.OUTLINEPRIMARY} ${styles.refresh}`}
            >
              <Icon
                className={`${
                  loading ? 'spinner' : ''
                } align-middle d-block text-primary`}
                name="icon-dgpass-refresh"
              />
            </Button>
          </div>
          <div className="d-inline-block float-end">
            <Button
              name="download"
              type={ButtonType.BUTTON}
              className={`${ButtonStyle.OUTLINEPRIMARY} ${styles.download} d-none d-sm-block`}
              wrapperClassname="pe-2"
              onClick={onDownload}
            >
              Download all
              <Icon
                className="align-middle ps-1 text-primary"
                name="icon-dgpass-download"
              />
            </Button>
            <Button
              name="download"
              type={ButtonType.BUTTON}
              className={`${ButtonStyle.OUTLINEPRIMARY} ${styles.download} d-sm-none`}
              wrapperClassname="pe-2"
              onClick={onDownload}
            >
              <Icon
                className="align-middle text-primary"
                name="icon-dgpass-download"
              />
            </Button>
          </div>
        </div>
      );
    }, [pagination.itemsPerPage, pagination.totalPages, loading]);

    const footerPagination = useMemo(() => {
      return (
        <>
          {(typeof withPagination !== 'boolean' || withPagination) &&
            data.length > 0 && (
              <div className={`pagination px-sm-3 pb-3 ${styles.pagination}`}>
                <ul className="list-inline">
                  <li
                    onClick={() => onChangePage(Direction.Left, PageType.First)}
                    className={`${styles.arrow} ${
                      pagination.currentPage === 1 && styles.disabled
                    } px-1 list-inline-item`}
                  >
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <Icon
                        className={`${
                          pagination.currentPage !== 1 && 'text-primary'
                        }`}
                        size={IconSize.SIZE_12}
                        name="icon-dgpass-chevrons-left"
                      />
                    </div>
                  </li>
                  <li
                    onClick={() => onChangePage(Direction.Left, PageType.Last)}
                    className={`${styles.arrow} ${
                      pagination.currentPage === 1 && styles.disabled
                    } px-1 list-inline-item`}
                  >
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <Icon
                        className={`${
                          pagination.currentPage !== 1 && 'text-primary'
                        }`}
                        size={IconSize.SIZE_12}
                        name="icon-dgpass-chevron-left"
                      />
                    </div>
                  </li>
                  <li className="list-inline-item px-1">
                    {pagination.currentPage} of {pagination.totalPages} pages
                  </li>
                  <li
                    onClick={() =>
                      onChangePage(Direction.Right, PageType.First)
                    }
                    className={`${styles.arrow} ${
                      pagination.currentPage === pagination.totalPages &&
                      styles.disabled
                    } px-1 list-inline-item`}
                  >
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <Icon
                        className={`${
                          pagination.currentPage !== pagination.totalPages &&
                          'text-primary'
                        }`}
                        size={IconSize.SIZE_12}
                        name="icon-dgpass-chevron-right"
                      />
                    </div>
                  </li>
                  <li
                    onClick={() => onChangePage(Direction.Right, PageType.Last)}
                    className={`${styles.arrow} ${
                      pagination.currentPage === pagination.totalPages &&
                      styles.disabled
                    } px-1 list-inline-item`}
                  >
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <Icon
                        className={`${
                          pagination.currentPage !== pagination.totalPages &&
                          'text-primary'
                        }`}
                        size={IconSize.SIZE_12}
                        name="icon-dgpass-chevrons-right"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            )}
        </>
      );
    }, [pagination.currentPage, pagination.totalPages]);

    const resultPerPage = useMemo(() => {
      return data.slice(
        (pagination.currentPage - 1) * pagination.itemsPerPage,
        pagination.currentPage * pagination.itemsPerPage,
      );
    }, [pagination.currentPage, pagination.itemsPerPage, data]);

    useEffect(() => {
      setPagination({
        itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
        currentPage: 1,
        totalPages: Math.ceil(data.length / DEFAULT_ITEMS_PER_PAGE),
      });
    }, [data]);

    return (
      <div className="table-wrapper bg-white">
        {headerPagination}
        <div className="table-responsive mb-4 mb-sm-0">
          <table className={`${styles.table} ${className} table`}>
            <thead>
              <tr>
                {columns.map((column) => (
                  <th className="px-3" key={column.key} scope="col">
                    {column.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                resultPerPage.map((item, index) => (
                  <tr key={item.key + index}>
                    {columns.map((column, columnIndex) => (
                      <td
                        className="px-3"
                        key={item[column.key] + index + columnIndex}
                      >
                        {column?.render?.(item[column.key], item) ??
                          item[column.key]}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="py-5" colSpan={columns.length}>
                    <img src={noDataIcon} className="mx-auto d-block" />
                    <p className="font-weight-medium text-center py-2">
                      {Message.NO_DATA_MESSAGE}
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {footerPagination}
      </div>
    );
  },
);
