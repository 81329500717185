import { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { Message } from '../constants';
import { createLogoutFlow } from 'services/OryService';
import { RequestedMethods } from 'enum/index';

export const useLogout = (requestedMethod: string) => {
  const [logoutUrl, setLogoutUrl] = useState<string>('');

  useEffect(() => {
    if (requestedMethod === RequestedMethods.Aal2) {
      createLogoutFlow()
        .then(({ data }) => {
          setLogoutUrl(data.logout_url);
        })
        .catch((err: AxiosError) => {
          toast.error(
            err.response?.data.error.message || Message.DEFAULT_ERROR_MESSAGE,
          );
        });
    }
  }, [requestedMethod]);

  return logoutUrl;
};
