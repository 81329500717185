import React, {
  FC,
  useContext,
  useEffect,
  useState,
  memo,
  useMemo,
  useCallback,
} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

import { routes } from '../../models/routes';
import { createLogoutFlow, getAvatar } from '../../services/OryService';
import { AuthContext } from '../../store/AuthContext';
import { LogoutDialog } from 'components';
import { toggleBodyOverflow } from 'utils';
import avatar from '../../assets/images/avatar.svg';
import LOGO from '../../assets/images/logo.svg';

import styles from './Header.module.scss';
import { Session } from '@ory/client';
import { UserInfo } from 'interfaces/index';
import { getUserInfo } from 'helpers/index';

const ProfileLink: FC<{
  userInfo: UserInfo;
  imageIndex: number;
}> = memo(({ userInfo, imageIndex }) => {
  return (
    <div className={`d-flex align-items-center p-1 ${styles.profileLink}`}>
      <div className="d-flex">
        <img
          className={imageIndex ? 'rounded-circle' : ''}
          src={imageIndex !== 0 ? getAvatar(imageIndex) : avatar}
          width={40}
          height={40}
          alt="Avatar"
        />
      </div>
      <div className="d-flex align-items-center px-1">
        <p className="fw-semibold">
          {`${userInfo.firstName} ${userInfo.lastName}`}
        </p>
      </div>
      <div className="d-flex pe-2">
        <i className="icon-dgpass-chevron-down text-primary" />
      </div>
    </div>
  );
});

export const Header: FC = memo(() => {
  const [logoutUrl, setLogoutUrl] = useState<string>();
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn, session, imageIndex } = useContext(AuthContext);
  const [expandNavBar, setExpandNavBar] = useState<boolean>(false);
  const [showLogoutDialog, setLogoutDialog] = useState<boolean>(false);
  const navigateAndScrollTo = useCallback((event: React.SyntheticEvent) => {
    event.preventDefault();
    window.scrollTo(0, 0);
    navigate('/');
  }, []);

  const userInfo: UserInfo = useMemo(
    () => getUserInfo(session as Session),
    [session],
  );

  useEffect(() => {
    if (isLoggedIn) {
      createLogoutFlow()
        .then(({ data }) => {
          setLogoutUrl(data.logout_url);
        })
        .catch(() => {
          navigate(routes.home);
        });
    }
  }, []);

  const onLogoutHandler = useCallback(() => {
    toggleBodyOverflow();
    setLogoutDialog(true);
  }, []);

  return (
    <>
      <nav
        id="navbar"
        className={`${location.pathname === routes.consent ? 'd-none' : ''} ${
          styles.navbar
        } with-shadow navbar navbar-expand-sm navbar-expand-lg sticky-top bg-white`}
      >
        <div className="container-fluid justify-content-around">
          <div className="row w-100">
            <div className="col-lg-7 col-4">
              <Link
                reloadDocument={true}
                to="/"
                onClick={navigateAndScrollTo}
                className="d-flex align-items-center navbar-brand text-primary"
              >
                <img
                  src={LOGO}
                  height={40}
                  alt="ZamPass - National Authentication and Access Control Service"
                />
                <div className="d-lg-none">ZamPass</div>
                <div className="d-none d-lg-block">
                  ZamPass - National Digital Identity and Authentication Service
                </div>
              </Link>
            </div>
            <div
              className={`${styles.menu} d-sm-none d-flex justify-content-end col-8 text-primary`}
            >
              {isLoggedIn && (
                <div
                  className={`d-flex align-items-center px-1 ${styles.imageBox} ${styles.hasImage}`}
                >
                  <img
                    className={imageIndex !== 0 ? 'rounded-circle' : ''}
                    src={imageIndex !== 0 ? getAvatar(imageIndex) : avatar}
                    width={40}
                    height={40}
                    alt="Avatar"
                  />
                </div>
              )}
              <button
                className="navbar-toggler navbar-light border-0 text-primary"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={(event) => {
                  event.preventDefault();
                  setExpandNavBar((show: boolean) => !show);
                }}
              >
                <span className="navbar-toggler-icon text-primary"></span>
              </button>
            </div>
            <div
              className={
                !expandNavBar
                  ? `${styles.menu} col-lg-5 col-8 justify-content-end align-items-center`
                  : 'col-lg-3 col-12 align-items-center'
              }
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                {isLoggedIn ? (
                  <>
                    <div className="d-sm-none">
                      <li>
                        <Link
                          to={routes.profileSettings}
                          className={`${styles.link} nav-link text-main text-end fs-5`}
                        >
                          <div className="d-flex align-items-center p-1">
                            <div className="d-flex pe-2">
                              <i
                                style={{ fontSize: '22px' }}
                                className="icon-dgpass-user-stroke text-primary"
                              />
                            </div>
                            <div className="d-flex">My profile</div>
                          </div>
                        </Link>
                      </li>
                      {!userInfo.verifiedEmail && (
                        <li>
                          <Link
                            to={routes.firstVerification}
                            className={`${styles.link} nav-link text-main text-end fs-5`}
                          >
                            <div className="d-flex align-items-center p-1">
                              <div className="d-flex pe-2">
                                <i className="icon-dgpass-email text-primary" />
                              </div>
                              <div className="d-flex">Confirm email</div>
                            </div>
                          </Link>
                        </li>
                      )}
                      <li>
                        <Link
                          to="#"
                          onClick={onLogoutHandler}
                          className={`${styles.link} nav-link text-main text-end fs-5`}
                        >
                          <div className="d-flex align-items-center p-1">
                            <div className="d-flex pe-1">
                              <i
                                style={{ fontSize: '22px' }}
                                className="icon-dgpass-logout text-primary"
                              />
                            </div>
                            <div className="d-flex">Logout</div>
                          </div>
                        </Link>
                      </li>
                    </div>
                    {!userInfo.verifiedEmail && (
                      <li className="d-none d-sm-block">
                        <Link
                          to={routes.firstVerification}
                          className={`${styles.link} nav-link text-main text-end fs-5`}
                        >
                          <div className="d-flex align-items-center p-1">
                            <div className="d-flex pe-2">
                              <i className="icon-dgpass-email text-primary" />
                            </div>
                            <div className="d-flex">Confirm email</div>
                          </div>
                        </Link>
                      </li>
                    )}
                    <li className="d-none d-sm-block">
                      <Dropdown align="end">
                        <Dropdown.Toggle
                          className={`no-hover profile-link-dropdown p-0 ${styles.dropdownToggle}`}
                          variant="success"
                        >
                          <ProfileLink
                            imageIndex={imageIndex}
                            userInfo={userInfo}
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href={routes.profileSettings}>
                            <div className="d-flex align-items-center p-1">
                              <div className="d-flex pe-2">
                                <i
                                  style={{ fontSize: '22px' }}
                                  className="icon-dgpass-user-stroke text-primary"
                                />
                              </div>
                              <div className="d-flex">My profile</div>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={onLogoutHandler}>
                            <div className="d-flex align-items-center p-1">
                              <div className="d-flex pe-2">
                                <i
                                  style={{ fontSize: '22px' }}
                                  className="icon-dgpass-logout text-primary"
                                />
                              </div>
                              <div className="d-flex">Logout</div>
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  </>
                ) : (
                  <li>
                    <Link
                      to={routes.firstVerification}
                      className={`${styles.link} nav-link text-main fs-5`}
                    >
                      <div className="d-flex align-items-center p-1">
                        <div className="d-flex pe-2">
                          <i className="icon-dgpass-email text-primary" />
                        </div>
                        <div className="d-flex">Confirm email</div>
                      </div>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </nav>
      {showLogoutDialog && (
        <LogoutDialog
          setLogoutDialog={(state) => {
            toggleBodyOverflow();
            setLogoutDialog(state);
          }}
          logoutUrl={logoutUrl}
        />
      )}
    </>
  );
});
