import {
  FC,
  memo,
  useMemo,
  useCallback,
  useEffect,
  useState,
  BaseSyntheticEvent,
} from 'react';
import Accordion from 'react-bootstrap/Accordion';

import { Icon, Input } from 'components';
import { AlignIcon, IconSize, QuestionType } from 'enum';
import { Constants } from '../../constants';

import styles from './Faq.module.scss';

interface Question {
  title: string;
  text: string;
  type: QuestionType;
}

interface AcordionsProps {
  questions: Question[];
}

interface CategoriesProps {
  onClick: (category: string) => void;
  activeCategory: string;
}

const CATEGORY_ICONS_MAP = new Map<QuestionType, string>([
  [QuestionType.Registration, 'icon-dgpass-registration'],
  [QuestionType.Mobile, 'icon-dgpass-mobile-app'],
  [QuestionType.Protection, 'icon-dgpass-protection'],
  [QuestionType.WebPortal, 'icon-dgpass-web-portal'],
  [QuestionType.Passwords, 'icon-dgpass-passwords'],
  [QuestionType.Support, 'icon-dgpass-support'],
]);

const Categories: FC<CategoriesProps> = memo(({ onClick, activeCategory }) => {
  return (
    <>
      {Object.values(QuestionType).map((item: string) => {
        const icon = CATEGORY_ICONS_MAP.get(item as QuestionType);
        return (
          <div key={item} className="col-lg-4 col-sm-12 my-3">
            <div
              onClick={() => onClick(item)}
              className={`${styles.category} ${
                activeCategory === item ? styles.active : ''
              } cursor-pointer py-4 px-3 bg-white rounded d-flex flex-row justify-content-start align-items-center`}
            >
              <p className="d-inline-block align-middle px-1">
                <Icon
                  name={icon as string}
                  className={`${
                    activeCategory === item ? 'text-white' : styles.icon
                  } fs-1`}
                />
              </p>
              <p
                className={`${styles.titleCategory} d-inline-block align-middle px-2 font-weight-medium`}
              >
                {item}
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
});

const Acordions: FC<AcordionsProps> = memo(({ questions }) => {
  return (
    <>
      {questions.map((item: Question, index) => {
        return (
          <Accordion.Item
            className={`${styles.acordionItem} my-3 font-weight-medium`}
            key={item.title + index}
            eventKey={String(index)}
          >
            <Accordion.Header>{item.title}</Accordion.Header>
            <Accordion.Body>
              <span dangerouslySetInnerHTML={{ __html: item.text }} />
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
    </>
  );
});

export const Faq: FC = memo(() => {
  const [questions, setQuestions] = useState<Question[]>(Constants.QUESTIONS);
  const [category, setCategory] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');

  const onChangeHandler = (e: BaseSyntheticEvent) => {
    const regex = new RegExp(
      `(?<!</?[^>]*|&[^;]*)(${e.target.value.toLowerCase()})`,
    );
    const updatedQuestions = e.target.value
      ? Constants.QUESTIONS.filter(
          (question) =>
            question.title
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            regex.test(question.text.toLowerCase()),
        )
      : category
      ? Constants.QUESTIONS.filter((question) => question.type === category)
      : Constants.QUESTIONS;
    setQuestions(updatedQuestions);
    setSearchValue(e.target.value);
  };

  const onCickCategoryHandler = useCallback((categoryParam: string) => {
    setCategory(categoryParam);
  }, []);

  useEffect(() => {
    if (category.length) {
      const updatedQuestions = Constants.QUESTIONS.filter(
        (question) => question.type === category,
      );
      setQuestions(updatedQuestions);
    }
  }, [category]);

  const activeCategory = useMemo(() => {
    return CATEGORY_ICONS_MAP.get(category as QuestionType);
  }, [category]);

  return (
    <>
      <div className="container-fluid faq-wrapper">
        <div
          className={`${styles.searchBar} search-bar row bg d-flex justify-content-sm-center align-items-center`}
        >
          <div className="col-sm-10 col-lg-3">
            <Input
              value={searchValue}
              onChange={onChangeHandler}
              className={`${styles.input} px-4 py-3`}
              name="search"
              placeholder="Search by section"
              icon={true}
              iconName="icon-dgpass-search"
              alignIcon={AlignIcon.right}
            />
          </div>
        </div>
      </div>
      <div className="container pb-5">
        <div
          className={`${styles.categorySection} categories-section row d-flex px-2`}
        >
          <div className="col-lg-12">
            <div className="mt-4">
              {searchValue.length ? (
                <h1 className="text-primary my-4 open-sans-bold">
                  Search results
                </h1>
              ) : (
                <h1 className="text-center m-3 open-sans-bold">
                  FAQ Categories
                </h1>
              )}
            </div>
            <div className="row">
              {searchValue.length === 0 && (
                <Categories
                  activeCategory={category}
                  onClick={onCickCategoryHandler}
                />
              )}
              <div className="question-wrapper">
                {searchValue.length === 0 && (
                  <h2 className="pt-5 pb-1">
                    {category ? (
                      <>
                        <p className="d-inline-block align-middle px-2">
                          <Icon
                            name={activeCategory as string}
                            className="text-primary"
                            size={IconSize.SIZE_46}
                          />
                        </p>
                        <p className="d-inline-block align-middle px-2 font-weight-medium">
                          {category}
                        </p>
                      </>
                    ) : (
                      'Most Popular Questions'
                    )}
                  </h2>
                )}
                {questions.length ? (
                  <Accordion>
                    <Acordions questions={questions} />
                  </Accordion>
                ) : (
                  <h4 className="text-center">No results found</h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
