import { FC, memo } from 'react';
import { Icon } from 'components';
import { IconSize } from 'enum/index';

export const Loading: FC<{ text: string }> = memo(({ text }) => (
  <div className="d-flex justify-content-center align-items-center vh-100 spinner-content">
    <div className="text-primary">
      <Icon
        className="loading d-block text-primary mx-auto"
        size={IconSize.SIZE_40}
        name="icon-dgpass-loading-data"
      />
      <p className="text-black font-weight-medium mt-1 text-center">{text}</p>
    </div>
  </div>
));
