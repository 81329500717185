import { FC, memo } from 'react';

import { Icon } from 'components';
import { IconSize, StatusClass, StatusIconName, StatusType } from 'enum';

interface StatusProps {
  type: StatusType;
  text: string;
  className?: string;
}

const statuSettings = new Map([
  [
    StatusType.Active,
    { icon: StatusClass.Active, name: StatusIconName.Active },
  ],
  [
    StatusType.Inactive,
    { icon: StatusClass.Inactive, name: StatusIconName.Inactive },
  ],
  [
    StatusType.Successful,
    { icon: StatusClass.Active, name: StatusIconName.Active },
  ],
  [
    StatusType.Revoked,
    { icon: StatusClass.Inactive, name: StatusIconName.Inactive },
  ],
  [
    StatusType.Verified,
    { icon: StatusClass.Verified, name: StatusIconName.Active },
  ],
  [
    StatusType.Unverified,
    { icon: StatusClass.Unverified, name: StatusIconName.Unverified },
  ],
]);

export const Status: FC<StatusProps> = memo(
  ({ type, text, className = '' }) => {
    const setting = statuSettings.get(type);

    return (
      <>
        <div className="d-inline status-wrapper">
          <Icon
            size={IconSize.SIZE_12}
            className={setting?.icon}
            name={setting?.name as string}
          />
        </div>
        <div
          className={`${setting?.icon} capitalise d-inline ps-1 ${className}`}
        >
          {text}
        </div>
      </>
    );
  },
);
