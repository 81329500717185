import { FC, memo } from 'react';

import styles from './Dialog.module.scss';

interface DialogProps {
  title?: string;
  titleClass?: string;
  subTitle?: string;
  children: React.ReactNode;
}

export const Dialog: FC<DialogProps> = memo(
  ({ title, titleClass, subTitle, children }) => {
    return (
      <div
        className={`${styles['logout-dialog-container']} h-100 row d-flex justify-content-center`}
      >
        <div className={styles['modal-backdrop']}></div>
        <div
          className={`${styles['modal-view']} d-flex align-items-center position-absolute bg-neutral-100 rounded col-11 py-5 p-lg-5 card-max-width`}
        >
          <div className="modal-content">
            {title && (
              <div>
                <h2 className={`text-center ${titleClass ?? ''}`}>{title}</h2>
              </div>
            )}
            {subTitle && (
              <div className="py-3">
                <p className="text-center font-weight-medium fs-6">
                  {subTitle}
                </p>
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    );
  },
);
