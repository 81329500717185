import React, { memo, FC } from 'react';

import { Icon } from 'components';

import styles from './CardSettings.module.scss';

interface CardSettingsProps {
  wrapperClassname?: string;
  title?: string;
  icon?: string;
  children: React.ReactNode;
}

export const CardSettings: FC<CardSettingsProps> = memo(
  ({ title, wrapperClassname, icon, children }) => {
    return (
      <div
        className={`${wrapperClassname} bg-light-100 mb-5 rounded overflow-hidden`}
      >
        <div className="row">
          {title && (
            <div className={`${styles.title}`}>
              <div className="py-3">
                <div className="d-inline-block px-lg-3 px-2">
                  {icon && <Icon name={icon} className="fs-5" />}
                </div>
                <div className="d-inline-block align-text-bottom">
                  <p className="fs-6 card-title">{title}</p>
                </div>
              </div>
            </div>
          )}
          <div className="col py-3 px-4 card-settings">
            <div className="card-body px-2">{children}</div>
          </div>
        </div>
      </div>
    );
  },
);
