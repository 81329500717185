import { FC, memo, BaseSyntheticEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Message } from '../../constants';
import LOGO from '../../assets/images/logo.svg';
import { Button, Checkbox, Spinner } from 'components';
import { ButtonStyle, ButtonType, InputType } from 'enum';
import { createFormDataConsent } from 'helpers';
import { getUserScope, submitUserScope } from '../../services/ConsentService';

import styles from './Consent.module.scss';

interface ConsentState {
  accepted: boolean;
  challenge: string;
  remember: boolean;
}

interface ScopeClaim {
  displayName: string;
  claims: string[];
}

interface ClientState {
  clientName: string;
  requestedScopes: string[];
  scopeClaims: ScopeClaim[];
}

export const Consent: FC = memo(() => {
  const [searchParams] = useSearchParams();
  const consentChallenge = searchParams.get('consent_challenge') || '';

  const [formState, setFormState] = useState<ConsentState>({
    accepted: false,
    challenge: consentChallenge,
    remember: false,
  });

  const [client, setClient] = useState<ClientState>();

  const onChangeRememberHandler = (e: BaseSyntheticEvent) => {
    setFormState((currentState) => {
      return {
        ...currentState,
        remember: e.target.checked,
      };
    });
  };

  const onSubmitHandler = (accepted: boolean) => {
    const { challenge, remember } = formState;
    submitUserScope(
      formState.challenge,
      createFormDataConsent(
        { Challenge: challenge, Remember: remember, Accepted: accepted },
        client?.requestedScopes ?? [],
      ),
    )
      .then(({ data }) => {
        window.location.replace(data);
      })
      .catch((error) => {
        toast.error(error.message || Message.DEFAULT_ERROR_MESSAGE);
      });
  };

  const renderCheckBox = (scope: string, index: number) => {
    return (
      <Checkbox
        key={index}
        type={InputType.CHECKBOX}
        name={scope}
        label={scope}
        labelClass={`${styles.scopeTitle} py-1`}
        checked={true}
        disabled={true}
      />
    );
  };

  useEffect(() => {
    getUserScope(consentChallenge)
      .then(({ data }) => {
        if (typeof data === 'string') {
          window.location.replace(data);
        } else {
          setClient(data as ClientState);
        }
      })
      .catch((error) => {
        toast.error(error.message || Message.DEFAULT_ERROR_MESSAGE);
      });
  }, []);

  return client ? (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="col-11 card-max-width">
        <div
          className={`${styles.content} bg-neutral-100 p-lg-5 py-4 px-3 rounded`}
        >
          <form action="">
            <div className="modal-content">
              <div className="align-self-center">
                <img
                  src={LOGO}
                  alt="ZamPass - National Authentication and Access Control Service"
                  className={`${styles.image} img-fluid`}
                />
              </div>
              <div className="py-3">
                <p
                  className={`${styles.subTitle} text-center open-sans-medium`}
                >
                  Application {client.clientName ?? ''} requests permissions to
                  access your personal data, as follows:
                </p>
              </div>
              {client.scopeClaims?.length &&
                client.scopeClaims.map((scope, scopeIndex) => (
                  <div className="row" key={scopeIndex}>
                    <p
                      className={`${styles.subTitle} open-sans-medium mt-3 mb-1`}
                    >
                      {scope.displayName}
                    </p>

                    {scope.claims?.length && scope.claims.map((claim, claimIndex) => (
                      <div className="col-md-6" key={claimIndex}>
                        {renderCheckBox(claim, claimIndex)}
                      </div>
                    ))}
                  </div>
                ))}
              <hr className={`${styles.divider}`}></hr>
              <div className="py-4">
                <p
                  className={`${styles.subTitle} text-center open-sans-medium`}
                >
                  Do you want to be asked again next time you attempt to sign
                  into the {client.clientName ?? ''}?
                </p>
              </div>
              <div className="py-2">
                <Checkbox
                  type={InputType.CHECKBOX}
                  key="Do not ask me again"
                  name="Do not ask me again"
                  label="Do not ask me again"
                  labelClass={`${styles.scopeTitle}`}
                  onChange={onChangeRememberHandler}
                  checked={formState.remember}
                />
              </div>
              <div className="py-4">
                <Button
                  key="Allow access"
                  name="Allow access"
                  className={`${ButtonStyle.PRIMARY} w-100`}
                  type={ButtonType.BUTTON}
                  onClick={() => onSubmitHandler(true)}
                >
                  Allow Access
                </Button>
              </div>
              <div className="py-1">
                <Button
                  key="Deny Access"
                  name="Deny Access"
                  className={`${ButtonStyle.OUTLINEPRIMARY} w-100`}
                  type={ButtonType.BUTTON}
                  onClick={() => onSubmitHandler(false)}
                >
                  Deny Access
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
});
