import ory from '../config/ory';
import {
  FrontendApiCreateBrowserLoginFlowRequest,
  FrontendApiCreateBrowserRegistrationFlowRequest,
} from '@ory/client';
import {
  FrontendApiUpdateLoginFlowRequest,
  FrontendApiUpdateRecoveryFlowRequest,
  FrontendApiUpdateRegistrationFlowRequest,
  FrontendApiUpdateVerificationFlowRequest,
  UpdateSettingsFlowBody,
} from '@ory/client/api';
import Axios from 'utils/axios';

export const createRegistrationFlow = (
  requestParameters: FrontendApiCreateBrowserRegistrationFlowRequest = {},
  defaultRequestOptions = {},
) => {
  return ory.createBrowserRegistrationFlow(
    requestParameters,
    defaultRequestOptions,
  );
};

export const getRegistrationFlow = (flowId: string) => {
  return ory.getRegistrationFlow({ id: flowId });
};

export const updateRegistrationFlow = (
  flowBody: FrontendApiUpdateRegistrationFlowRequest,
  defaultRequestOptions = {},
) => {
  return ory.updateRegistrationFlow(flowBody, defaultRequestOptions);
};

export const createLoginFlow = (
  requestParameters: FrontendApiCreateBrowserLoginFlowRequest = {},
  defaultRequestOptions = {},
) => {
  return ory.createBrowserLoginFlow(requestParameters, defaultRequestOptions);
};

export const getLoginFlow = (flowId: string) => {
  return ory.getLoginFlow({ id: flowId });
};

export const updateLoginFlow = (
  flowBody: FrontendApiUpdateLoginFlowRequest,
  defaultRequestOptions = {},
) => {
  return ory.updateLoginFlow(flowBody, defaultRequestOptions);
};

export const getVerificationFlow = (flowId: string) => {
  return ory.getVerificationFlow({ id: flowId });
};

export const createVerificationFlow = (defaultRequestOptions = {}) => {
  return ory.createBrowserVerificationFlow(defaultRequestOptions);
};

export const updateVerificationFlow = (
  flowBody: FrontendApiUpdateVerificationFlowRequest,
  defaultRequestOptions = {},
) => {
  return ory.updateVerificationFlow(flowBody, defaultRequestOptions);
};

export const createLogoutFlow = () => {
  return ory.createBrowserLogoutFlow();
};

export const updateLogoutFlow = (
  logoutToken: string,
  defaultRequestOptions = {},
) => {
  return ory.updateLogoutFlow({ token: logoutToken }, defaultRequestOptions);
};

export const getSession = () => {
  return ory.toSession();
};

export const createRecoveryFlow = () => {
  return ory.createBrowserRecoveryFlow();
};

export const getRecoveryFlow = (flowId: string) => {
  return ory.getRecoveryFlow({ id: flowId });
};

export const updateRecoveryFlow = (
  flowBody: FrontendApiUpdateRecoveryFlowRequest,
  defaultRequestOptions = {},
) => {
  return ory.updateRecoveryFlow(flowBody, defaultRequestOptions);
};

export const createBrowserSettingsFlow = (defaultRequestOptions = {}) => {
  return ory.createBrowserSettingsFlow(defaultRequestOptions);
};

export const getSettingsFlow = (flowId: string) => {
  return ory.getSettingsFlow({ id: flowId });
};

export const updateSettingsFlow = (
  flowId: string,
  flowBody: string,
  defaultRequestOptions = {},
) => {
  return ory.updateSettingsFlow(
    {
      flow: flowId,
      updateSettingsFlowBody: flowBody as unknown as UpdateSettingsFlowBody,
    },
    defaultRequestOptions,
  );
};

export const getConsentSession = () => {
  return Axios.get('/self-service/settings/consent', {
    withCredentials: true,
    baseURL: window.REACT_APP_KRATOS_BROWSER_URL,
  });
};

export const updateAvatar = (data: FormData) => {
  return Axios.post('/self-service/avatar', data, {
    withCredentials: true,
    baseURL: window.REACT_APP_KRATOS_BROWSER_URL,
  });
};

export const deleteAvatar = () => {
  return Axios.delete('/self-service/avatar', {
    withCredentials: true,
    baseURL: window.REACT_APP_KRATOS_BROWSER_URL,
  });
};

export const checkAvatar = () => {
  return Axios.get('/self-service/avatar', {
    withCredentials: true,
    baseURL: window.REACT_APP_KRATOS_BROWSER_URL,
  });
};

export const getAvatar = (index: number) => {
  return `${window.REACT_APP_KRATOS_BROWSER_URL}/self-service/avatar?index=${index}`;
};

export const deleteConsentSessionByClientId = (clientId: string) => {
  return Axios.delete(`/self-service/settings/consent?clientId=${clientId}`, {
    withCredentials: true,
    baseURL: window.REACT_APP_KRATOS_BROWSER_URL,
  });
};

export const getSignInSessions = () => {
  return Axios.get('/sessions/recent', {
    withCredentials: true,
    baseURL: window.REACT_APP_KRATOS_BROWSER_URL,
  });
};

export const getError = (id: string) =>
  Axios.get('self-service/errors', {
    params: { id },
    withCredentials: true,
    baseURL: window.REACT_APP_KRATOS_BROWSER_URL,
  });
