import React, { useState } from 'react';
import { Session } from '@ory/client';
import { checkAvatar } from 'services/OryService';

interface AuthContextProps {
  isLoggedIn: boolean;
  imageIndex: number;
  session?: Session | null;
  login: (session: Session) => void;
  logout: () => void;
  updateImage: (imageIndex: number) => void;
}

export const AuthContext = React.createContext<AuthContextProps>({
  isLoggedIn: false,
  imageIndex: 0,
  session: null,
  login: () => ({}),
  logout: () => ({}),
  updateImage: () => ({}),
});

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [session, setSession] = useState<Session | null>();
  const [imageIndex, setImageIndex] = useState<number>(1);

  const loginHandler = (userSession: Session) => {
    setSession(userSession);
    checkAvatar().then(({ data }) => {
      setImageIndex(data ? 1 : 0);
    });
  };

  const logoutHandler = () => {
    setSession(null);
  };

  const updateImage = (index: number) => setImageIndex(index);

  const contextValue = {
    isLoggedIn: !!session,
    imageIndex,
    session: session,
    login: loginHandler,
    logout: logoutHandler,
    updateImage,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
