import { memo, FC } from 'react';

import { Error } from 'components';
import { InputElementBase } from '../../models/input-element-base';

export const Checkbox: FC<InputElementBase> = memo(
  ({
    checked,
    tabIndex,
    alt,
    disabled,
    type,
    wrapperClassname,
    className,
    label,
    labelClass,
    name,
    value,
    onChange,
    readOnly,
    required,
    error,
    labelPosition,
    hidden,
  }) => {
    return (
      <div className={wrapperClassname}>
        <div
          className={`${hidden ? 'd-none' : ''} form-check focus-label ${
            error ? 'error' : ''
          }`}
        >
          <label
            htmlFor={name}
            className={`form-check-label ${labelClass} ${labelPosition} ${
              required ? 'required' : ''
            }`}
          >
            {label}
          </label>
          <input
            id={name}
            className={`form-check-input ${className}`}
            alt={alt}
            aria-label={name}
            data-testid={name}
            tabIndex={tabIndex}
            type={type}
            value={value}
            name={name}
            onChange={onChange}
            disabled={disabled}
            readOnly={readOnly}
            required={required}
            checked={checked}
          />
        </div>
        {error && <Error message={error} />}
      </div>
    );
  },
);
