import { FC, HTMLAttributeReferrerPolicy, useEffect, memo } from 'react';

import { InputType } from 'enum';

interface WebauthScriptProps {
  async: boolean;
  crossorigin?: 'anonymous' | 'use-credentials';
  id: string;
  integrity: string;
  nonce: string;
  referrerpolicy: HTMLAttributeReferrerPolicy;
  src: string;
  type: InputType.SCRIPT;
}

export const WebauthScript: FC<WebauthScriptProps> = memo(
  ({ async, crossorigin, id, integrity, nonce, referrerpolicy, src, type }) => {
    useEffect(() => {
      const head = document.querySelector('head');
      const script = document.createElement('script');

      script.setAttribute('src', src);
      script.setAttribute('async', String(async));
      script.setAttribute('crossOrigin', String(crossorigin));
      script.setAttribute('id', String(id));
      script.setAttribute('integrity', String(integrity));
      script.setAttribute('nonce', String(nonce));
      script.setAttribute('referrerpolicy', String(referrerpolicy));

      head?.appendChild(script);

      return () => {
        head?.removeChild(script);
      };
    }, [src]);

    return (
      <script
        nonce={nonce}
        id={id}
        async={async}
        src={src}
        type={type}
        integrity={integrity}
        referrerPolicy={referrerpolicy}
        crossOrigin={crossorigin}
      ></script>
    );
  },
);
