import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ProtectedRoute } from '../guard/ProtectedRoute';
import { routes } from '../models/routes';
import { Verification } from '../pages';
import { Consent } from '../pages/Consent/Consent';
import { Recovery } from '../pages/Recovery/Recovery';
import { VerificationEmail } from '../pages/VerificationEmail/VerificationEmail';
import { Authentication } from '../pages/Authentication/Authentication';
import { Logout } from '../pages/Logout/Logout';
import { About } from '../pages/About/About';
import { TermsAndConditions } from '../pages/TermsAndConditions/TermsAndConditions';
import { LayoutInformation } from 'components';
import { PrivacyAndPolicy } from '../pages/PrivacyAndPolicy/PrivacyAndPolicy';
import { Errors } from '../pages/Errors/Errors';
import { NotFound } from '../pages/NotFound/NotFound';
import { Faq } from 'pages/Faq/Faq';
import { DeleteAccount } from 'pages/DeleteAccount/DeleteAccount';

const Layout = React.lazy(() =>
  import('../components/Layout/Layout').then((module) => ({
    default: module.Layout,
  })),
);

const Home = React.lazy(() =>
  import('../pages/Home/Home').then((module) => ({ default: module.Home })),
);

const Settings = React.lazy(() =>
  import('../pages/Settings/Settings').then((module) => ({
    default: module.Settings,
  })),
);

export const Navigation = () => {
  return useRoutes([
    {
      path: routes.home,
      element: (
        <Layout>
          <Home />
        </Layout>
      ),
    },
    {
      path: routes.login,
      element: (
        <Layout>
          <Authentication />
        </Layout>
      ),
    },
    {
      path: routes.registration,
      element: (
        <Layout>
          <Authentication />
        </Layout>
      ),
    },
    {
      path: routes.firstVerification,
      element: (
        <Layout>
          <Verification />
        </Layout>
      ),
    },
    {
      path: routes.consent,
      element: (
        <Layout>
          <Consent />
        </Layout>
      ),
    },
    {
      path: routes.verificationCreated,
      element: (
        <Layout>
          <VerificationEmail />
        </Layout>
      ),
    },
    {
      path: routes.recoveryPassword,
      element: (
        <Layout>
          <Recovery />
        </Layout>
      ),
    },
    {
      path: routes.logout,
      element: (
        <Layout>
          <Logout />
        </Layout>
      ),
    },
    {
      path: routes.about,
      element: (
        <LayoutInformation>
          <About />
        </LayoutInformation>
      ),
    },
    {
      path: routes.error,
      element: (
        <LayoutInformation className="bg-neutral-150">
          <Errors />
        </LayoutInformation>
      ),
    },
    {
      path: routes.settings,
      element: (
        <ProtectedRoute>
          <Layout>
            <Settings />
          </Layout>
        </ProtectedRoute>
      ),
    },
    {
      path: routes.profileSettings,
      element: (
        <ProtectedRoute>
          <Layout>
            <Settings />
          </Layout>
        </ProtectedRoute>
      ),
    },
    {
      path: routes.accessSettings,
      element: (
        <ProtectedRoute>
          <Layout>
            <Settings />
          </Layout>
        </ProtectedRoute>
      ),
    },
    {
      path: routes.notifications,
      element: (
        <ProtectedRoute>
          <Layout>
            <Settings />
          </Layout>
        </ProtectedRoute>
      ),
    },
    {
      path: routes.activity,
      element: (
        <ProtectedRoute>
          <Layout>
            <Settings />
          </Layout>
        </ProtectedRoute>
      ),
    },
    {
      path: routes.deleteAccount,
      element: (
        <ProtectedRoute>
          <Layout>
            <Settings />
          </Layout>
        </ProtectedRoute>
      ),
    },
    {
      path: routes.termsAndConditions,
      element: (
        <LayoutInformation>
          <TermsAndConditions />
        </LayoutInformation>
      ),
    },
    {
      path: routes.deleteAcountInfo,
      element: (
        <LayoutInformation>
          <DeleteAccount />
        </LayoutInformation>
      ),
    },
    {
      path: routes.privacyAndPolicy,
      element: (
        <LayoutInformation>
          <PrivacyAndPolicy />
        </LayoutInformation>
      ),
    },
    {
      path: routes.faq,
      element: (
        <LayoutInformation>
          <Faq />
        </LayoutInformation>
      ),
    },
    {
      path: '*',
      element: (
        <LayoutInformation className="bg-neutral-150">
          <NotFound />
        </LayoutInformation>
      ),
    },
  ]);
};
