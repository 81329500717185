import Axios from '../utils/axios';

export const getUserScope = (consentChallenge: string) => {
  return Axios.get(`/consent?challenge=${consentChallenge}`);
};

export const submitUserScope = (challenge: string, payload: FormData) => {
  return Axios.post(`/consent?challenge=${challenge}`, payload);
};

export const logoutUserByChallenge = (challenge: string, payload: string) => {
  return Axios.post(`/logout?challenge=${challenge}`, payload);
};

export const getLogoutUserByChallenge = (challenge: string) => {
  return Axios.get(`/logout?challenge=${challenge}`);
};
