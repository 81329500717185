import React, { BaseSyntheticEvent, memo, FC } from 'react';

import { ButtonRadiusNone, ButtonType } from 'enum';
import styles from './Button.module.scss';

interface ButtonProps {
  type: ButtonType;
  name?: string;
  hidden?: boolean;
  className?: string;
  disabled?: boolean;
  value?: string;
  borderRadiusNone?: ButtonRadiusNone;
  wrapperClassname?: string;
  children: React.ReactNode;
  formNoValidate?: boolean;
  onClick?: (e: BaseSyntheticEvent) => void;
}

export const Button: FC<ButtonProps> = memo(
  ({
    type,
    name,
    className,
    disabled,
    wrapperClassname,
    value,
    hidden,
    children,
    borderRadiusNone,
    formNoValidate,
    onClick,
  }) => {
    return (
      <div className={`${wrapperClassname} ${hidden ? 'd-none' : ''} clearfix`}>
        <button
          name={name}
          onClick={onClick}
          disabled={!!disabled}
          type={type}
          value={value}
          formNoValidate={formNoValidate}
          className={`${className} ${
            borderRadiusNone ? styles[borderRadiusNone] : ''
          }`}
        >
          {children}
        </button>
      </div>
    );
  },
);
