import { memo, FC } from 'react';
import { SettingsFlow } from '@ory/client';

import {
  ButtonStyle,
  ButtonType,
  Client,
  DividerType,
  FlowStrategy,
  InputType,
  MessageType,
} from 'enum';
import { getMessages, onFilterFlowNodes } from 'helpers';
import {
  CardSettings,
  Button,
  Divider,
  Hints,
  Flow,
  Message,
} from 'components';
import { CardSetting, NodeFlow } from 'interfaces';
import { Maps } from '../../../../constants';

import styles from './UserAccess.module.scss';

interface AccessSettingsProps {
  activeStrategy: FlowStrategy;
  flow: SettingsFlow;
  displayPassword: boolean;
  setDisplayPassword: (value: boolean) => void;
  onSubmit: (
    values: { [key: string]: string },
    flowStrategy?: FlowStrategy,
  ) => void;
}

export const CARD_SETTINGS: CardSetting[] = [
  {
    wrapperClassname: '',
    title: 'Change Password',
    icon: 'icon-dgpass-password',
    text: 'Password requirements:',
    listHints: [
      'At least 8 characters',
      'All characters allowed',
      'Your password cannot be similar to your user ID',
      'Passwords previously exposed in data breaches are not allowed',
    ],
    flowStrategy: FlowStrategy.Password,
  },
  {
    wrapperClassname: 'mt-3',
    title: 'Manage MFA Backup Recovery Codes',
    icon: 'icon-dgpass-recovery-codes',
    text: 'Backup recovery codes are pre-generated codes  which can be used if you lose access to your TOTP or WebAuthn devices. The codes are valid only once and should be regenerated from time to time.',
    flowStrategy: FlowStrategy.LookupSecret,
  },
  {
    wrapperClassname: 'mt-3',
    title: 'Manage Hardware Tokens',
    icon: 'icon-dgpass-hardware-tokens',
    flowStrategy: FlowStrategy.Webauthn,
  },
  {
    wrapperClassname: 'mt-3',
    title: 'Manage Passkey',
    icon: 'icon-dgpass-hardware-tokens',
    text: 'Passkeys are an easier and more secure alternative to passwords. They let you sign-in with just your fingerprint, face scan, or screen lock.',
    flowStrategy: FlowStrategy.Passkey,
  },
  {
    wrapperClassname: '',
    title: 'Manage MFA methods',
    icon: 'icon-dgpass-mfa',
    text: 'By enabling multi-factor authentication, you introduce an additional verification step that can protect user login or self-service actions, such as updating account information or credentials, from malicious actors.',
    flowStrategy: FlowStrategy.Mfa,
  },
  {
    wrapperClassname: 'mt-3',
    title: 'Manage MFA Authenticator',
    icon: 'icon-dgpass-mobile',
    flowStrategy: FlowStrategy.Totp,
  },
];

export const UserAccess: FC<AccessSettingsProps> = memo(
  ({ flow, activeStrategy, displayPassword, setDisplayPassword, onSubmit }) => {
    const onFilterFlowByFlowStrategy = (flowStrategy: FlowStrategy) => {
      if (flowStrategy === FlowStrategy.Mfa) {
        const flowState = onFilterFlowNodes(flow, flowStrategy);
        const flowNodes = flowState.ui.nodes.map(
          (node: NodeFlow, index: number) => {
            return {
              ...node,
              ...node.attributes,
              priority:
                node?.attributes?.type === InputType.CHECKBOX
                  ? Maps.MFA_PRIORITY_MAP.get(node?.attributes?.name as Client)
                  : index + 100,
            };
          },
        );
        return {
          ...flowState,
          ui: {
            ...flowState.ui,
            nodes: flowNodes.sort(
              (a: NodeFlow, b: NodeFlow) => a.priority! - b.priority!,
            ),
          },
        };
      }

      return onFilterFlowNodes(flow, flowStrategy);
    };

    const onRenderFlow = (
      flowState: SettingsFlow,
      card: CardSetting,
      hasPassword = true,
    ) => {
      {
        return (
          flowState &&
          hasPassword && (
            <Flow
              flowStrategy={card.flowStrategy}
              activeStrategy={activeStrategy}
              showMessages={card.flowStrategy !== FlowStrategy.Password}
              onSubmit={onSubmit}
              flow={onFilterFlowByFlowStrategy(card.flowStrategy)}
            />
          )
        );
      }
    };

    const onRenderCard = (card: CardSetting) => {
      const message = getMessages(flow?.ui.messages || []);
      let hasLookup = false;

      if (card.flowStrategy === FlowStrategy.LookupSecret) {
        const flowLookup = onFilterFlowByFlowStrategy(card.flowStrategy);
        hasLookup = flowLookup.ui.nodes?.length == 2;
      }

      return (
        <CardSettings
          icon={card.icon}
          key={card.title}
          wrapperClassname={card.wrapperClassname}
          title={card.title}
        >
          {card.flowStrategy === FlowStrategy.Password &&
            activeStrategy === card.flowStrategy &&
            message &&
            !displayPassword && (
              <div className="py-3">
                <Message type={MessageType.Text} message={message} />
              </div>
            )}

          {((card.text && card.flowStrategy !== FlowStrategy.LookupSecret) ||
            hasLookup) && <p className="py-2">{card.text}</p>}

          {card.listHints && (
            <>
              <Hints list={card.listHints} />
              <Divider type={DividerType.Line} className="my-3" />
            </>
          )}

          {card.flowStrategy === FlowStrategy.Password
            ? onRenderFlow(flow, card, displayPassword)
            : onRenderFlow(flow, card)}

          {!displayPassword && card.flowStrategy === FlowStrategy.Password && (
            <Button
              name="Change password"
              className={`${ButtonStyle.OUTLINEPRIMARY} float-end mt-3`}
              type={ButtonType.BUTTON}
              onClick={() => setDisplayPassword(true)}
            >
              Change Password
            </Button>
          )}
        </CardSettings>
      );
    };

    const onFilterCardSettings = (flowStrategies: FlowStrategy[]) => {
      return CARD_SETTINGS.filter((card) =>
        flowStrategies.includes(card.flowStrategy),
      );
    };

    return (
      <div className="col-12 user-access-settings">
        <div className="row">
          <div className={`${styles.column} col`}>
            {onFilterCardSettings([
              FlowStrategy.Password,
              FlowStrategy.LookupSecret,
              FlowStrategy.Webauthn,
              FlowStrategy.Passkey,
            ]).map((card) => onRenderCard(card))}
          </div>
          <div className={`${styles.column} col`}>
            {onFilterCardSettings([FlowStrategy.Mfa, FlowStrategy.Totp]).map(
              (card) => onRenderCard(card),
            )}
          </div>
        </div>
      </div>
    );
  },
);
