import React, { useContext } from 'react';
import { AuthContext } from '../store/AuthContext';
import { Navigate } from 'react-router-dom';

import { routes } from '../models/routes';

export const ProtectedRoute = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { isLoggedIn } = useContext(AuthContext);

  if (!isLoggedIn) {
    return <Navigate to={routes.login} />;
  }

  return children;
};
