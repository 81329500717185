import { FC, memo } from 'react';

import { MessageType, ServerMessageType } from 'enum';
import { Error } from 'interfaces';

import styles from './Message.module.scss';

interface MessageProps {
  message: Error;
  type: MessageType;
}

export const Message: FC<MessageProps> = memo(({ message, type }) => {
  const messageTextMap = new Map([
    [
      ServerMessageType.Error,
      <div
        key={ServerMessageType.Error}
        className={`${styles.message} ${styles.error} position-relative text-center text-black px-3 py-3`}
      >
        <p className="position-relative">{message.text}</p>
      </div>,
    ],
    [
      ServerMessageType.Info,
      <div
        key={ServerMessageType.Info}
        className={`${styles.message} ${styles.info} position-relative text-center text-black px-3 py-3`}
      >
        <p className="position-relative">{message.text}</p>
      </div>,
    ],
    [
      ServerMessageType.Success,
      <div
        key={ServerMessageType.Success}
        className={`${styles.message} ${styles.success} position-relative text-center text-black px-3 py-3`}
      >
        <p className="position-relative">{message.text}</p>
      </div>,
    ],
    [
      ServerMessageType.Default,
      <div
        key={ServerMessageType.Default}
        className="position-relative text-center text-black px-3 py-3"
      >
        <p className="position-relative">{message.text}</p>
      </div>,
    ],
  ]);

  const messageTitleMap = new Map([
    [
      ServerMessageType.Error,
      <h2 key={ServerMessageType.Error} className="text-secondary">
        {message.text}
      </h2>,
    ],
    [
      ServerMessageType.Info,
      <h2 key={ServerMessageType.Info}>{message.text}</h2>,
    ],
    [
      ServerMessageType.Success,
      <h2 key={ServerMessageType.Success} className="text-primary">
        {message.text}
      </h2>,
    ],
    [
      ServerMessageType.Default,
      <h2 key={ServerMessageType.Info}>{message.text}</h2>,
    ],
  ]);
  return (
    <>
      {type === MessageType.Text
        ? messageTextMap.get(message.type as ServerMessageType)
        : messageTitleMap.get(message.type as ServerMessageType)}
    </>
  );
});
