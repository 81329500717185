import { FC, memo } from 'react';
import { useLocation } from 'react-router';

import { routes } from '../../models/routes';

interface ImageProps {
  wrapperClassname?: string;
  className?: string;
  src: string;
  text?: string;
  width?: number;
  height?: number;
  id?: string;
  hidden?: boolean;
}

export const Image: FC<ImageProps> = memo(
  ({ src, wrapperClassname, className, text, width, height, hidden, id }) => {
    const location = useLocation();
    return (
      <div
        className={`${hidden ? 'd-none' : ''} ${wrapperClassname ?? ''} ${
          location.pathname.includes(routes.settings)
            ? ''
            : 'd-flex flex-column justify-content-center align-items-center d-none d-lg-flex image-wrapper-card'
        }`}
      >
        {text && <p className="py-1">{text}</p>}
        <img
          id={id}
          src={src}
          width={width}
          height={height}
          className={`${className}`}
        />
      </div>
    );
  },
);
