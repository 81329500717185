import { useState, useCallback, memo, FC, useMemo } from 'react';

import { Button, Icon, TitleContents } from 'components';
import { ButtonStyle, ButtonType, IconSize } from 'enum';
import { getTodayDate, onDownloadPdf, scrollContent } from 'utils';

import styles from './TermsAndConditions.module.scss';

const PDF_FILENAME = 'zampass_term_and_conditions';
const MENU_HEIGHT = 76;
const CONTENT_TITLES = [
  'Introduction',
  'Acceptance of the Terms and Conditions',
  'How to use ZamPass',
  'ZamPass authentication methods',
  'Cookies',
  "User's conduct rules",
  'License',
  'The cost of service',
  'Availability of the service',
  'Amendments to the Terms and Conditions',
  'Hyperlinking to our Content',
  'iFrames',
  'Content Liability',
  'Your Privacy',
  'Reservation of Rights',
  'Removal of links from our service',
  'Disclaimer',
];

export const TermsAndConditions: FC = memo(() => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const onDownloadHandler = useCallback(async () => {
    const downloadElement = document.getElementById(
      'downloadElement',
    ) as HTMLElement;
    await onDownloadPdf(downloadElement, PDF_FILENAME);
  }, []);

  const onClickTitleHandler = useCallback((index: number) => {
    setActiveIndex(index);
    scrollContent(index, MENU_HEIGHT);
  }, []);

  const today = useMemo(() => {
    return getTodayDate();
  }, []);

  return (
    <div className="container terms-conditions-wrapper py-4">
      <div id="downloadElement" className={`${styles.row} row lh-lg`}>
        <div
          className={`${styles.content} col-lg-9 col-md-8 col-sm-12 mt-3 mt-sm-0`}
        >
          <h1 className="open-sans-bold">Terms and conditions</h1>
          <div className="clearfix mt-3 mt-sm-0">
            <div className="d-inline-block">
              <span className="font-weight-medium last-update">
                Last update: {today}
              </span>
            </div>
            <div className="d-inline-block float-end">
              <Button
                onClick={onDownloadHandler}
                className={`${ButtonStyle.PRIMARY} button-page`}
                type={ButtonType.BUTTON}
                name="download"
              >
                Download PDF{' '}
                <Icon
                  className="text-light align-middle"
                  name="icon-dgpass-download"
                />
              </Button>
            </div>
          </div>
          <div className="pt-3">
            <div id="item1" className="info-box">
              <h4 className="open-sans-semibold pb-3">1. Introduction</h4>
              <p>Welcome to ZamPass Portal!</p>
              <p>
                {
                  "These terms and conditions outline the rules and regulations for the use of ZamPass's Service."
                }
              </p>
              <p>
                By accessing this service we assume you accept these terms and
                conditions. Do not continue to use ZamPass if you do not agree
                to all of the terms and conditions stated on this page.
              </p>
            </div>
            <div id="item2" className="info-box">
              <h4 className="open-sans-semibold py-3">
                2. Acceptance of the Terms and Conditions
              </h4>
              <p>
                By using ZamPass for authentication, the User will be deemed to
                have accepted these Terms and Conditions and to have agreed to
                the processing and storage of personal data under the Law, which
                may be challenged in the manner prescribed by law.
              </p>
            </div>
            <div id="item3" className="info-box">
              <h4 className="open-sans-semibold py-3">3. How to use ZamPass</h4>
              <p>
                ZamPass can be used in the context of{' '}
                <a target="_blank" href="https://eservices.gov.zm/">
                  eservices.gov.zm
                </a>{' '}
                or any Zambia public e-services portal which is integrated with
                ZamPass. User uses the ZamPass service for authentication, and
                optionally for authorization in the context electronic services.
              </p>
              <p>
                It is recommended to access the ZamPass service through the
                latest three versions of the most popular browsers (Chrome,
                Edge, Safari, Firefox). The portal can be accessed both from
                desktop and mobile devices (with the operating systems iOS,
                Android).
              </p>
            </div>
            <div id="item4" className="info-box">
              <h4 className="open-sans-semibold py-3">
                4. ZamPass authentication methods
              </h4>
              <p>
                ZamPass provides an integrated, secure, and versatile mechanism
                for user authentication in information systems (electronic
                services). The function of user authentication can be performed
                through various authentication methods. The authentication
                through ZamPass can be made using any of the following methods:
              </p>
              <ul className="list-unstyled ps-3">
                <li>1) user name and password;</li>
                <li>2) security key (key pass);</li>
                <li>3) QR authentication (via Mobile ID app);</li>
                <li>4) multi factor authentication:</li>
                <ul className="list-unstyled ps-3">
                  <li>- TOTP (via Mobile ID app);</li>
                  <li>- Push notification (via Mobile ID app);</li>
                  <li>- SMS;</li>
                  <li>- Email;</li>
                  <li>- Authenticator app (Google, Microsoft).</li>
                </ul>
              </ul>
              <p>
                To choose the authentication method is the exclusive
                responsibility and right of the user.
              </p>
            </div>
            <div id="item5" className="info-box">
              <h4 className="open-sans-semibold py-3">5. Cookies</h4>
              <p>
                {
                  "We employ the use of cookies. By accessing ZamPass portal, you agreed to use cookies in agreement with the ZamPass's Privacy and Cookies Policy."
                }
              </p>
              <p>
                Most interactive services use cookies to let us retrieve the
                user’s details for each visit. Cookies are used by our service
                to enable the functionality of certain areas to make it easier
                for people visiting our service.
              </p>
            </div>
            <div id="item6" className="info-box">
              <h4 className="open-sans-semibold py-3">
                {"6. User's conduct rules"}
              </h4>
              <p>
                By using ZamPass the user agrees not to upload, post, send
                emails or any content with viruses or other computer codes,
                files or programs designed to interrupt, destroy or limit the
                functionality of any software or hardware or telecommunications
                equipment associated with ZamPass.
              </p>
              <p>
                The user also agrees not to interface with the servers or
                networks that are related to ZamPass or to violate any
                procedure, policy or regulation of the networks related to
                ZamPass, including not to:
              </p>
              <ul className="list-unstyled ps-3">
                <li>
                  - pretend to be another person while creating and account and
                  using ZamPass;
                </li>
                <li>- use ZamPass for illegal purposes;</li>
                <li>- re-sell or export services associated with ZamPass.</li>
              </ul>
              <p>
                Before using ZamPass, the user may be required to provide
                certain information, such as personal information,
                identification information, contact details etc. as part of the
                enrollment process. In this case, the responsibility for the
                accuracy of the entered data (etc.) lies entirely with the user.
              </p>
              <p>
                In case of logging in to the portal, the user is responsible for
                maintaining the confidentiality of any associated login
                information. The user is also responsible for any operations on
                his/her account.
              </p>
              <p>
                The user is advised not to share the login information with a
                third party. The user will be solely responsible for the
                consequences of sharing such information.
              </p>
            </div>
            <div id="item7" className="info-box">
              <h4 className="open-sans-semibold py-3">7. License</h4>
              <p>
                Unless otherwise stated, ZamPass and/or its licensors own the
                intellectual property rights for all material on ZamPass
                service. All intellectual property rights are reserved. You may
                access this from ZamPass Portal for your own personal use
                subjected to restrictions set in these terms and conditions.
              </p>
              <p>You must not:</p>
              <ul>
                <li>Republish material from ZamPass Portal</li>
                <li>Sell, rent or sub-license material from ZamPass Portal</li>
                <li>
                  Reproduce, duplicate or copy material from ZamPass Portal
                </li>
                <li>Redistribute content from ZamPass Portal</li>
              </ul>
              <p>This Agreement shall begin on the date hereof.</p>
            </div>
            <div id="item8" className="info-box">
              <h4 className="open-sans-semibold py-3">
                8. The cost of service
              </h4>
              <p>
                There is no cost for using ZamPass by users accessing Zambia
                electronic public services.
              </p>
            </div>
            <div id="item9" className="info-box">
              <h4 className="open-sans-semibold py-3">
                9. Availability of the service
              </h4>
              <p>
                ZamPass is a 24/7 service. The guaranteed period for the agreed
                service availability level is 98.99%. The maintenance works,
                normally will be carried out outside working hours, except for
                emergencies or other unpredictable situations.
              </p>
            </div>
            <div id="item10" className="info-box">
              <h4 className="open-sans-semibold py-3">
                10. Amendments to the Terms and Conditions
              </h4>
              <p>
                ZamPass reserves the right to change these Terms and Conditions.
                The current version of the Terms and Conditions will be that
                published on the portal. It is the exclusive responsibility of
                the user to read the Terms and Conditions before using ZamPass.
              </p>
            </div>
            <div id="item11" className="info-box">
              <h4 className="open-sans-semibold py-3">
                11. Hyperlinking to our Content
              </h4>
              <p>
                The following organizations may link to our Service without
                prior written approval:
              </p>
              <ul>
                <li>Government agencies;</li>
                <li>Search engines;</li>
                <li>News organizations;</li>
                <li>
                  Online directory distributors may link to our Service in the
                  same manner as they hyperlink to the Services of other listed
                  businesses; and
                </li>
                <li>
                  System wide Accredited Businesses except soliciting non-profit
                  organizations, charity shopping malls, and charity fundraising
                  groups which may not hyperlink to our Web site.
                </li>
              </ul>
              <p>
                These organizations may link to our home page, to publications
                or to other Service information so long as the link: (a) is not
                in any way deceptive; (b) does not falsely imply sponsorship,
                endorsement or approval of the linking party and its products
                and/or services; and (c) fits within the context of the linking
                party’s site.
              </p>
              <p>
                We may consider and approve other link requests from the
                following types of organizations:
              </p>
              <ul>
                <li>
                  commonly-known consumer and/or business information sources;
                </li>
                <li>dot.com community sites;</li>
                <li>associations or other groups representing charities;</li>
                <li>online directory distributors;</li>
                <li>internet portals;</li>
                <li>accounting, law and consulting firms; and</li>
                <li>educational institutions and trade associations.</li>
              </ul>
            </div>
            <div id="item12" className="info-box">
              <h4 className="open-sans-semibold py-3">12. iFrames</h4>
              <p>
                Without prior approval and written permission, you may not
                create frames around our Webpages that alter in any way the
                visual presentation or appearance of our Service.
              </p>
            </div>
            <div id="item13" className="info-box">
              <h4 className="open-sans-semibold py-3">13. Content Liability</h4>
              <p>
                We shall not be hold responsible for any content that appears on
                your Service. You agree to protect and defend us against all
                claims that is rising on your Service. No link(s) should appear
                on any Service that may be interpreted as libelous, obscene or
                criminal, or which infringes, otherwise violates, or advocates
                the infringement or other violation of, any third party rights.
              </p>
            </div>
            <div id="item14" className="info-box">
              <h4 className="open-sans-semibold py-3">14. Your Privacy</h4>
              <p>Please read our Privacy and Cookies Policy.</p>
            </div>
            <div id="item15" className="info-box">
              <h4 className="open-sans-semibold py-3">
                15. Reservation of Rights
              </h4>
              <p>
                We reserve the right to request that you remove all links or any
                particular link to our Service. You approve to immediately
                remove all links to our Service upon request. We also reserve
                the right to amen these terms and conditions and it’s linking
                policy at any time. By continuously linking to our Service, you
                agree to be bound to and follow these linking terms and
                conditions.
              </p>
            </div>
            <div id="item16" className="info-box">
              <h4 className="open-sans-semibold py-3">
                16. Removal of links from our service
              </h4>
              <p>
                If you find any link on our Service that is offensive for any
                reason, you are free to contact and inform us any moment. We
                will consider requests to remove links but we are not obligated
                to or so or to respond to you directly.
              </p>
              <p>
                We do not ensure that the information on this service is
                correct, we do not warrant its completeness or accuracy; nor do
                we promise to ensure that the service remains available or that
                the material on the service is kept up to date.
              </p>
            </div>
            <div id="item17" className="info-box">
              <h4 className="open-sans-semibold py-3">17. Disclaimer</h4>
              <p>
                To the maximum extent permitted by applicable law, we exclude
                all representations, warranties and conditions relating to our
                service and the use of this service. Nothing in this disclaimer
                will:
              </p>
              <ul>
                <li>
                  limit or exclude our or your liability for death or personal
                  injury;
                </li>
                <li>
                  limit or exclude our or your liability for fraud or fraudulent
                  misrepresentation;
                </li>
                <li>
                  limit any of our or your liabilities in any way that is not
                  permitted under applicable law; or
                </li>
                <li>
                  exclude any of our or your liabilities that may not be
                  excluded under applicable law.
                </li>
              </ul>
              <p>
                The limitations and prohibitions of liability set in this
                Section and elsewhere in this disclaimer: (a) are subject to the
                preceding paragraph; and (b) govern all liabilities arising
                under the disclaimer, including liabilities arising in contract,
                in tort and for breach of statutory duty.
              </p>
              <p>
                As long as the service and the information and services on the
                service are provided free of charge, we will not be liable for
                any loss or damage of any nature.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <div className={`sticky-top ${styles.sticky}`}>
            <div className="py-2">
              <div className="d-inline-block align-middle">
                <Icon size={IconSize.SIZE_20} name="icon-dgpass-contents" />
              </div>
              <div className="d-inline-block align-middle px-2">
                <h5 className="font-weight-medium">Contents</h5>
              </div>
            </div>
            <div className={`${styles.headings} bg-white`}>
              <TitleContents
                activeIndex={activeIndex}
                onClick={onClickTitleHandler}
                items={CONTENT_TITLES}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
