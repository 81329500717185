'use client';
import {
  BaseSyntheticEvent,
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useDropzone,
  Accept,
  DropzoneOptions,
  FileRejection,
} from 'react-dropzone';
import { toast } from 'react-toastify';
import { createPortal } from 'react-dom';

import {
  checkAvatar,
  deleteAvatar,
  getAvatar,
  updateAvatar,
} from 'services/OryService';
import { UserInfo } from 'interfaces';
import { ProgressBar } from 'react-bootstrap';
import { Button, Dialog } from '../../components';
import { ButtonStyle, ButtonType, UploaderLocation } from 'enum';

import styles from './Uploader.module.scss';
import { AuthContext } from 'store/AuthContext';
import { Message } from '../../constants';

interface Options {
  accept: Accept;
  maxFiles: number;
  maxSize: number;
  message: string;
}

interface ComponentProps {
  options?: Options;
  userInfo: UserInfo;
  location: UploaderLocation;
}

enum CodeError {
  DefaultMaxFileSize = 'file-too-large',
  MaxFileSize = 'file-too-large-extend',
}

const DefaultOptions: Options = {
  accept: { 'image/webp': [] },
  maxFiles: 1,
  maxSize: 300000,
  message: 'validators.maxFileSize',
};

const UPLOADER_TIME = 15;
const PROGRESS_RATE = 6.6;

const showErrors = (fileRejections: FileRejection[]) => {
  fileRejections.forEach((fileRejection: FileRejection) => {
    toast.error(fileRejection.errors[0].message);
  });
};

const DeleteAvatarDialog: FC<{
  setConfirmationDialog: () => void;
  deleteHandler: () => void;
}> = memo(({ setConfirmationDialog, deleteHandler }) => {
  return createPortal(
    <Dialog
      title="Delete profile image?"
      titleClass="text-error"
      subTitle="Are you sure you want to delete?"
    >
      <div className="d-flex py-3 justify-content-between">
        <div className="d-inline-flex w-100">
          <Button
            className={`${ButtonStyle.DANGER} w-100 text-white`}
            type={ButtonType.BUTTON}
            name="yes"
            wrapperClassname="w-100 px-1"
            onClick={deleteHandler}
          >
            Delete
          </Button>
        </div>
        <div className="d-inline-flex w-100">
          <Button
            className={`${ButtonStyle.OUTLINEPRIMARY} w-100`}
            type={ButtonType.BUTTON}
            name="cancel"
            wrapperClassname="w-100 px-1"
            onClick={setConfirmationDialog}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>,
    document.querySelector('#root') as Element,
  );
});

export const Uploader: FC<ComponentProps> = memo(
  ({ options = DefaultOptions, userInfo, location }) => {
    const [value, setValue] = useState<boolean>(false);
    const [showConfirmationDialog, setConfirmationDialog] =
      useState<boolean>(false);
    const [progressValue, setProgressValue] = useState<number>(0);
    const { imageIndex, updateImage } = useContext(AuthContext);

    useEffect(() => {
      checkAvatar().then(({ data }) => {
        if (data) {
          setValue(true);
          updateImage(imageIndex + 1);
        }
      });
    }, []);

    const onDrop = useCallback(
      async <T extends File>(
        acceptedFiles: T[],
        fileRejections: FileRejection[],
      ) => {
        const formData = new FormData();
        let currentValue = 0;
        try {
          const file = acceptedFiles?.[0];
          fileRejections.length && showErrors(fileRejections);

          if (!file) {
            return;
          }

          formData.append('file', file, file.name);

          updateAvatar(formData).then(() => {
            value && setValue(true);
            const intervalUploader = setInterval(() => {
              currentValue += 1;
              setProgressValue(Math.round(currentValue * PROGRESS_RATE));
              if (currentValue > UPLOADER_TIME) {
                updateImage(imageIndex + 1);
                setValue(true);
                clearInterval(intervalUploader);
                setProgressValue(0);
              }
            }, 1000);
          });
        } catch (error: any) {
          toast.error(error.message || Message.DEFAULT_ERROR_MESSAGE);
        }
      },
      [imageIndex],
    );

    const toggleConfirmationDialogHandler = useCallback(
      (show: boolean) => () => setConfirmationDialog(show),
      [],
    );

    const initiateConfirmationDialog = useCallback((e: BaseSyntheticEvent) => {
      setConfirmationDialog(true);
      e?.preventDefault();
      e?.stopPropagation();
    }, []);

    const deleteAvatarHandler = useCallback(() => {
      try {
        deleteAvatar().then(() => {
          setValue(false);
          updateImage(0);
          setConfirmationDialog(false);
        });
      } catch (error: any) {
        toast.error(error?.message);
      }
    }, []);

    const userImage = useMemo(
      () => (
        <img src={getAvatar(progressValue)} width={80} height={80} alt="Logo" />
      ),
      [progressValue],
    );

    const sizeLengthValidator = useCallback((file: any) => {
      if (file.size > options.maxSize) {
        return {
          code: CodeError.MaxFileSize,
          message: `File is larger than ${options.maxSize / 1000} KB`,
        };
      }

      return null;
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      validator: sizeLengthValidator,
      ...(options as DropzoneOptions),
    });

    return (
      <div
        className={`${
          location === UploaderLocation.SideBar
            ? styles.uploader
            : 'd-block d-sm-none'
        }`}
      >
        <div
          className={`d-flex flex-direction-column ${
            location === UploaderLocation.SideBar ? 'p-3' : 'py-3'
          }`}
        >
          <div>
            <div>
              <input {...getInputProps()} />
              <>
                {value ? (
                  <div
                    className={`d-flex justify-content-center align-items-center text-center overflow-hidden ${styles.imageBox} ${styles.hasImage}`}
                  >
                    <div className={`${styles.overlayFixed}`}></div>
                    <i
                      {...getRootProps()}
                      className="icon-dgpass-upload cursor-pointer position-absolute"
                    />
                    <i
                      onClick={initiateConfirmationDialog}
                      className="icon-dgpass-delete cursor-pointer position-absolute"
                    />
                    <div className="delimiter position-absolute"></div>
                    {userImage}
                  </div>
                ) : (
                  <div
                    {...getRootProps()}
                    className={`d-flex justify-content-center align-items-center text-center cursor-pointer ${styles.imageBox}`}
                  >
                    <i
                      className="icon-dgpass-user-camera"
                      style={{ fontSize: '25px' }}
                    />
                  </div>
                )}
              </>
            </div>
          </div>
          <div className="d-flex align-items-center ps-2">
            <h5 className="mb-0 open-sans-semibold text-break">
              {`${userInfo.firstName} ${userInfo.lastName}`}
            </h5>
          </div>
        </div>
        {progressValue > 0 && (
          <div className="w-100 py-2">
            <ProgressBar now={progressValue} label={`${progressValue}%`} />
          </div>
        )}
        {showConfirmationDialog && (
          <DeleteAvatarDialog
            setConfirmationDialog={toggleConfirmationDialogHandler(false)}
            deleteHandler={deleteAvatarHandler}
          />
        )}
      </div>
    );
  },
);
