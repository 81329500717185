import { FC, memo } from 'react';
import { Button } from 'components';

import { ActionButton } from 'interfaces/index';

interface GroupButtonsProps {
  buttons: ActionButton[];
  wrapperClassname?: string;
}

export const GroupButtons: FC<GroupButtonsProps> = memo(
  ({ buttons, wrapperClassname = '' }) => (
    <div className={wrapperClassname ?? ''}>
      {buttons.map((button, index: number) => (
        <Button key={index} {...button}>
          {button.children}
        </Button>
      ))}
    </div>
  ),
);
