import React, { FC, memo, useState } from 'react';
import { Icon, Tooltip } from '../../components';

import { FlowStrategy, TooltipDirection } from '../../enum';
import { InputElementBase } from '../../models/input-element-base';

import styles from './Text.module.scss';

interface TextProps extends InputElementBase {
  id?: string;
  wrapperClassname?: string;
  className?: string;
  text?: string;
  code?: string;
  hidden?: boolean;
  flowStrategy: FlowStrategy;
}

export const Text: FC<TextProps> = memo(
  ({
    wrapperClassname,
    id,
    className,
    text,
    code,
    hidden,
    flowStrategy,
    onChange,
  }) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const handleTooltipVisibility = () => {
      if (!showTooltip) {
        setShowTooltip((prev) => !prev);
        setTimeout(() => {
          setShowTooltip(false);
        }, 1000);
      }
    };

    const onCopyToClipBoard = () => {
      const copyText = document.getElementById(
        flowStrategy,
      ) as HTMLInputElement;
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
      handleTooltipVisibility();
    };

    return (
      <div
        id={id}
        className={`${
          hidden ? 'd-none' : ''
        } ${wrapperClassname} text-wrapper mb-2`}
      >
        <p className="py-1">{text}</p>
        <div className={className}>
          <div className={`${styles.pre} d-flex py-3 ps-3 pe-4`}>
            <div
              className={`${styles.copy} copy-text-wrapper`}
              onClick={onCopyToClipBoard}
            >
              <Tooltip
                content="Copied"
                direction={TooltipDirection.TOP}
                delay={0}
                show={showTooltip}
              >
                <Icon
                  name="icon-dgpass-copy-clipboard"
                  className="fs-5 text-white"
                />
              </Tooltip>
            </div>
            <textarea
              rows={flowStrategy === FlowStrategy.LookupSecret ? 3 : 1}
              className={`${styles.textarea} border-0 bg-transparent w-100 focus-none`}
              onChange={onChange}
              value={code}
              id={flowStrategy}
            />
          </div>
        </div>
      </div>
    );
  },
);
