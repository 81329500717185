import { FC, memo } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';

import image from 'assets/images/404.svg';
import { Link } from 'react-router-dom';
import { routes } from '../../models/routes';

import styles from './NotFound.module.scss';

export const NotFound: FC = memo(() => {
  return (
    <Row className={`me-0 ms-0 ${styles.container}`}>
      <Col sm="auto">
        <Image src={image} className={styles.image} />
      </Col>
      <Col sm="auto" className={`${styles.title} open-sans-medium`}>
        Error 404 - Page Not Found
      </Col>
      <Col sm="auto" className={`${styles.message} open-sans-medium`}>
        The page you requested could not be found.
      </Col>
      <Col sm="auto" className="pt-4">
        <Link to={routes.home}>
          <Button className={styles.button}>Back to Main Page</Button>
        </Link>
      </Col>
    </Row>
  );
});
