import { Suspense, useContext, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import { Navigation } from './navigation/Navigation';
import { getSession } from './services/OryService';
import { AuthContext } from './store/AuthContext';
import './App.scss';

export const App = () => {
  const [session, setSession] = useState<boolean>(false);
  const context = useContext(AuthContext);

  useEffect(() => {
    getSession()
      .then(({ data }) => {
        context.login(data);
        setSession(true);
      })
      .catch(() => {
        context.logout();
        setSession(true);
      });
  }, []);

  return (
    <Suspense fallback="Loading application layout, wait please">
      {session && <Navigation />}
      <ToastContainer position="bottom-right" />
    </Suspense>
  );
};
