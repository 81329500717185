import { FrontendApi, Configuration } from '@ory/client';
const basePath = window.REACT_APP_KRATOS_BROWSER_URL;
const ory = new FrontendApi(
  new Configuration({
    basePath,
    baseOptions: {
      withCredentials: true,
    },
  }),
);

export default ory;
