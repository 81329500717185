import { memo, FC } from 'react';

import styles from './TitleContents.module.scss';

interface TitleContentsProps {
  items: string[];
  onClick: (index: number) => void;
  activeIndex: number;
}

export const TitleContents: FC<TitleContentsProps> = memo(
  ({ items, onClick, activeIndex }) => {
    return (
      <ul className="ps-0 lh-base list-unstyled">
        {items?.map((item, index) => (
          <li
            className={`cursor-pointer font-weight-medium position-relative px-4 py-2 ${
              activeIndex === index + 1 ? styles.active : ''
            } ${styles.listItem}`}
            onClick={() => onClick(index + 1)}
            key={index + 1}
          >{`${index + 1}. ${item}`}</li>
        ))}
      </ul>
    );
  },
);
