import { FC, memo, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { VerificationFlow } from '@ory/client';
import { createPortal } from 'react-dom';
import { toast } from 'react-toastify';

import { Flow } from 'components';
import {
  createVerificationFlow,
  getVerificationFlow,
} from '../../services/OryService';
import {
  FlowStrategy,
  MessageType,
  ServerMessageType,
  StrategySignUp,
} from 'enum';
import { routes } from '../../models/routes';
import { ErrorGlobal } from 'interfaces';
import { getMessages, renderMessageByType } from 'helpers';
import { Message, Maps } from '../../constants/';

const messageTextMap = new Map([
  [ServerMessageType.Default, 'Verify your ZamPass account'],
]);

export const Verification: FC = memo(() => {
  const [flow, setFlow] = useState<VerificationFlow>();
  const [errorServer, setError] = useState<ErrorGlobal>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const message = getMessages(flow?.ui.messages || []);

  const onHandleErrors = (strategy: StrategySignUp, err: AxiosError) => {
    switch (err.response?.status) {
      case 400:
        if (strategy === StrategySignUp.CreateVerification) {
          navigate(routes.login);
        } else if (strategy === StrategySignUp.UpdateVerification) {
          setError(err.response?.data?.error);
        }
        break;
      case 410:
        if (strategy === StrategySignUp.GetVerification) {
          navigate(routes.firstVerification);
        } else if (strategy === StrategySignUp.UpdateVerification) {
          const newFlowID = err.response.data.use_flow_id;
          onGetVerificationFlow(newFlowID, true);
        }
        break;
      case 403:
        navigate(routes.firstVerification);
        break;
      default:
        toast.error(
          err.response?.data.error.message || Message.DEFAULT_ERROR_MESSAGE,
        );
    }
  };

  const onGetVerificationFlow = (flowId: string, withError = false) => {
    getVerificationFlow(String(flowId))
      .then(({ data }) => {
        setFlow(data);
        if (withError) {
          navigate(routes.firstVerification);
        }
      })
      .catch((error: AxiosError) => {
        onHandleErrors(StrategySignUp.GetVerification, error);
      });
  };

  const onCreateVerificationFlow = () => {
    createVerificationFlow()
      .then(({ data }) => {
        setFlow(data);
        navigate(`${routes.firstVerification}?flow=${data.id}`);
      })
      .catch((error) => {
        onHandleErrors(StrategySignUp.CreateVerification, error);
      });
  };

  useEffect(() => {
    const flowId = searchParams.get('flow');
    if (flowId) {
      onGetVerificationFlow(flowId);
    } else {
      onCreateVerificationFlow();
    }
  }, []);

  return createPortal(
    <div className="container verification-wrapper">
      <div className="h-100 row d-flex justify-content-center">
        <div className="d-flex align-items-center position-absolute bg-neutral-100 rounded col-11 py-5 p-lg-5 top-25 card-max-width">
          <div className="modal-content">
            <div className="text-center">
              {renderMessageByType(
                Maps.MESSAGE_TITLE_VERIFICATION_MAP,
                message,
                MessageType.Title,
              )}
            </div>
            <div className="py-1">
              {renderMessageByType(messageTextMap, message, MessageType.Text)}
            </div>
            {flow && (
              <Flow
                flowStrategy={FlowStrategy.FirstVerification}
                flow={flow}
                error={errorServer}
              />
            )}
          </div>
        </div>
      </div>
    </div>,
    document.querySelector('#root') as Element,
  );
});
