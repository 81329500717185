import { memo, FC } from 'react';
import { useLocation } from 'react-router';

import { DividerType } from 'enum';
import { routes } from '../../models/routes';

import styles from './Divider.module.scss';

interface DividerProps {
  className?: string;
  text?: string;
  type: DividerType;
}

export const Divider: FC<DividerProps> = memo(({ className, text, type }) => {
  const location = useLocation();
  return type === DividerType.Line ? (
    <div className={`m-auto ${className} ${styles.divider}`}></div>
  ) : (
    <div
      className={
        location.pathname === routes.login ? 'd-none d-lg-flex' : 'row py-3'
      }
    >
      <div className="col-5">
        <div className={`m-auto ${className} ${styles.divider}`}></div>
      </div>
      <div className={`col-2 text-center ${styles.text}`}>{text}</div>
      <div className="col-5">
        <div className={`m-auto ${className} ${styles.divider}`}></div>
      </div>
    </div>
  );
});
