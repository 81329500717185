import { FC, Dispatch, SetStateAction, memo } from 'react';
import { createPortal } from 'react-dom';

import { Button, Dialog } from 'components';
import { ButtonStyle, ButtonType } from 'enum';

interface LogoutDialogProps {
  setLogoutDialog: Dispatch<SetStateAction<boolean>>;
  logoutUrl: string | undefined;
}

export const LogoutDialog: FC<LogoutDialogProps> = memo(
  ({ setLogoutDialog, logoutUrl }) => {
    const onLogout = () => {
      logoutUrl && window.location.replace(logoutUrl);
    };

    return createPortal(
      <Dialog
        title="Confirm log out"
        subTitle="Are you sure you want to log out?"
      >
        <div className="py-3">
          <Button
            onClick={onLogout}
            className={`${ButtonStyle.PRIMARY} w-100 text-white`}
            type={ButtonType.BUTTON}
            name="yes"
          >
            Yes
          </Button>
        </div>
        <div className="py-2">
          <Button
            className={`${ButtonStyle.OUTLINEPRIMARY} w-100`}
            type={ButtonType.BUTTON}
            name="cancel"
            onClick={() => setLogoutDialog(false)}
          >
            Cancel
          </Button>
        </div>
      </Dialog>,
      document.querySelector('#root') as Element,
    );
  },
);
