import { memo, FC } from 'react';

export const About: FC = memo(() => {
  return (
    <div className="container about-wrapper py-4">
      <div className="row lh-lg">
        <div className="col-lg-9 col-sm-12">
          <h1 className="open-sans-bold pb-2">About</h1>
          <p>
            Welcome to ZamPass, a simple, fast and secure digital access key to
            electronic public services.
          </p>
          <p>
            With ZamPass you can easily access electronic public services in
            Zambia. A pair of credentials that represents the digital and
            personal identity of each citizen, recognized by the government to
            use digital services in a personalized and secure manner. By
            guaranteeing everyone access to online services, that is always the
            same and intuitive, ZamPass facilitates the use of electronic
            services and simplifies the relationship of citizens with government
            institutions.
          </p>
          <p>
            {
              "ZamPass it's based on an open-source solution and facilitates single sign-on (SSO), delegation via OpenID Connect and offers a secure and modern central identity management solution with MFA, passwordless, WebAuthn. It covers session management, flows for login, registration, account recovery, account verification, MFA, and many more."
            }
          </p>
          <p>
            {
              "As Operator of ZamPass, we're dedicated to providing you with the very best of authentication methods in a secure and convenient manner. The interface of ZamPass is user-friendly and easy to understand, and the processes are simple to follow and intuitive."
            }
          </p>
          <p>
            {
              "We hope you enjoy using the platform as much as we enjoy offering them to you. If you have any questions or comments, please don't hesitate to contact us."
            }
          </p>
          <p>Sincerely,</p>
          <p>ZamPass team</p>
          <p>Smart Zambia Institute</p>
        </div>
      </div>
    </div>
  );
});
