import { FC, memo, useMemo } from 'react';

interface Hint {
  key: number;
  text: string;
}
interface ComponentProps {
  list?: string[];
}

export const Hints: FC<ComponentProps> = memo(({ list }: ComponentProps) => {
  const items = useMemo(
    () =>
      list?.reduce(
        (acc: Hint[], currentValue: string, currentIndex: number) => {
          return [...acc, { key: currentIndex, text: currentValue }];
        },
        [],
      ),
    [list],
  );

  return (
    <ul>
      {items?.map((item) => (
        <li key={item.key}>{item.text}</li>
      ))}
    </ul>
  );
});

export const HintsFn = (list?: string[]) => <Hints list={list} />;
