import { Maps } from 'constants/maps';
import { InputType, Client } from 'enum/index';

export const EMAIL_NODE = {
  attributes: {
    disabled: false,
    hidden: false,
    name: 'transient_email',
    node_type: 'input',
    required: true,
    type: InputType.EMAIL,
    value: '',
  },
  group: 'default',
  priority: Maps.SIGN_UP_PRIORITY_MAP.get(Client.Email) ?? 2,
  messages: [],
  meta: {
    label: {
      context: {
        title: 'Email Address',
      },
      id: 100054321,
      text: 'Email Address',
      type: 'info',
    },
    type: 'input',
  },
};

export const HIDDEN_EMAIL_NODE = {
  attributes: {
    disabled: false,
    hidden: true,
    name: 'transient_payload',
    node_type: 'input',
    required: false,
    type: InputType.HIDDEN,
    value: '',
  },
  group: 'default',
  priority: 99,
  messages: [],
  meta: {
    label: {},
    type: 'input',
  },
};
