import React, { FC, memo } from 'react';

import { Header, Footer } from 'components';

import styles from '../../pages/Home/Home.module.scss';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: FC<LayoutProps> = memo(({ children }) => {
  return (
    <>
      <Header />
      <div className={`${styles.mainBg}`}>
        <main id="mainContainer" className="vh-70">
          {children}
        </main>
      </div>
      <Footer />
    </>
  );
});
