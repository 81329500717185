import { FC, BaseSyntheticEvent, memo } from 'react';

import { ButtonRadiusNone, ButtonStyle, ButtonType } from 'enum';
import { Button } from 'components';

interface Tab {
  name: string;
  active: boolean;
}

interface TabsProps {
  tabs: Tab[];
  onChange?: (event: BaseSyntheticEvent) => void;
}

export const Tabs: FC<TabsProps> = memo(({ tabs, onChange }: TabsProps) => {
  return (
    <div className="mb-2 tabs-container">
      {tabs.length &&
        tabs.map((tab, index: number) => (
          <Button
            wrapperClassname="d-inline-block"
            key={tab.name}
            name={tab.name}
            borderRadiusNone={
              index == 0
                ? ButtonRadiusNone.right
                : index == tabs.length - 1
                ? ButtonRadiusNone.left
                : ButtonRadiusNone.both
            }
            className={`${
              tab.active ? ButtonStyle.SECONDARY : ButtonStyle.OUTLINEPRIMARY
            } mt-3`}
            type={ButtonType.BUTTON}
            onClick={onChange}
          >
            {tab.name}
          </Button>
        ))}
    </div>
  );
});
