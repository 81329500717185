import { FC, memo } from 'react';

import { Error } from 'components';
import { InputElementBase } from '../../models/input-element-base';

export const Radio: FC<InputElementBase> = memo(
  ({
    id,
    defaultChecked,
    checked,
    tabIndex,
    alt,
    value,
    disabled,
    wrapperClassname = '',
    className = '',
    label,
    labelClass = '',
    name,
    onChange,
    readOnly,
    required,
    error,
    labelPosition = '',
  }) => {
    return (
      <div className={wrapperClassname}>
        <div className={`form-check focus-label ${error ? 'error' : ''}`}>
          <label
            htmlFor={name}
            className={`form-check-label ${labelClass} ${labelPosition} ${
              required ? 'required' : ''
            }`}
          >
            {label}
          </label>
          <input
            key={id}
            id={name}
            className={`form-check-input ${className}`}
            alt={alt}
            aria-label={name}
            data-testid={name}
            tabIndex={tabIndex}
            type="radio"
            name={name}
            onChange={onChange}
            disabled={disabled}
            readOnly={readOnly}
            required={required}
            checked={checked}
            value={value}
            defaultChecked={defaultChecked}
          />
        </div>
        {error && <Error message={error} />}
      </div>
    );
  },
);
