import { FC, memo } from 'react';

import { Footer, Header } from 'components';

import styles from './LayoutInformation.module.scss';

interface LayoutInformationProps {
  children: React.ReactNode;
  className?: string;
}

export const LayoutInformation: FC<LayoutInformationProps> = memo(
  ({ children, className = '' }) => {
    return (
      <>
        <Header />
        <main
          className={`${styles.layout} ${className}`}
          id="informationContainer"
        >
          {children}
        </main>
        <Footer />
      </>
    );
  },
);
