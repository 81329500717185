import { FC, memo, useState, useEffect, BaseSyntheticEvent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Message } from '../../constants/';
import { createFormBody } from '../../helpers';
import {
  getLogoutUserByChallenge,
  logoutUserByChallenge,
} from '../../services/ConsentService';
import { createLogoutFlow, updateLogoutFlow } from '../../services/OryService';
import { Button, Checkbox } from 'components';
import { ButtonStyle, ButtonType, InputType } from '../../enum';

import styles from './Logout.module.scss';

export const Logout: FC = memo(() => {
  const [logoutPass, setLogoutPass] = useState<boolean>(false);
  const [clientName, setClientName] = useState<string>('');
  const [searchParams] = useSearchParams();
  const logoutChallenge = searchParams.get('logout_challenge');

  const acceptLogoutHandler = (
    challenge: string,
    accepted: boolean,
    logoutToken = '',
  ) => {
    logoutUserByChallenge(challenge, createFormBody({ challenge, accepted }))
      .then(({ data }) => {
        if (typeof data === 'string') {
          if (logoutToken) {
            updateLogoutFlow(logoutToken).then(() => {
              window.location.replace(data);
            });
          } else {
            window.location.replace(data);
          }
        }
      })
      .catch((error) => {
        toast.error(error.message || Message.DEFAULT_ERROR_MESSAGE);
      });
  };

  const onCreateLogoutFlow = (challenge: string, accepted: boolean) => {
    createLogoutFlow()
      .then(({ data }) => {
        acceptLogoutHandler(challenge, accepted, data.logout_token);
      })
      .catch(() => {
        acceptLogoutHandler(challenge, accepted);
      });
  };

  const onLogoutUseByChallenge = (challenge: string, accepted = true) => {
    if (logoutPass && accepted) {
      onCreateLogoutFlow(challenge, accepted);
    } else {
      acceptLogoutHandler(challenge, accepted);
    }
  };

  const onChangeHandler = (event: BaseSyntheticEvent) => {
    setLogoutPass(event.target.checked);
  };

  useEffect(() => {
    if (logoutChallenge) {
      getLogoutUserByChallenge(logoutChallenge)
        .then(({ data }) => {
          if (data.clientName) {
            setClientName(data.clientName);
          }
        })
        .catch((error) => {
          toast.error(error.message || Message.DEFAULT_ERROR_MESSAGE);
        });
    }
  }, []);

  return (
    <div className="h-100 row d-flex justify-content-center">
      <div
        className={`${styles.content} rounded align-items-center position-absolute bg-neutral-100 col-11 py-5 p-lg-5 top-25 card-max-width`}
      >
        <div className="modal-content">
          <div>
            <p className="text-center font-weight-medium fs-4">
              Confirm log out
            </p>
          </div>
          <div className="py-3">
            <p className="open-sans-medium text-center font-weight-medium fs-6">
              {`Are you sure you want to log out ${
                clientName ? 'from ' + clientName : ''
              }?`}
            </p>
          </div>
          <div className="py-2 font-weight-medium fs-6">
            <Checkbox
              type={InputType.CHECKBOX}
              name="ZamPass"
              label="Logout from ZamPass as well"
              className="fs-6"
              onChange={onChangeHandler}
            />
          </div>
          <div className="py-3">
            <Button
              className={`${ButtonStyle.PRIMARY} w-100`}
              type={ButtonType.BUTTON}
              name="confirm"
              onClick={() =>
                onLogoutUseByChallenge(logoutChallenge as string, true)
              }
            >
              Yes
            </Button>
          </div>
          <div className="py-2">
            <Button
              className={`${ButtonStyle.OUTLINEPRIMARY} w-100`}
              type={ButtonType.BUTTON}
              name="no"
              onClick={() =>
                onLogoutUseByChallenge(logoutChallenge as string, false)
              }
            >
              No
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});
