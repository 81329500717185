import { useEffect, useState } from 'react';

export const useBreakpoint = (breakpoint = 768) => {
  const [exceeded, setExceeded] = useState<boolean>(
    window.innerWidth <= breakpoint,
  );
  const handleWindowSizeChange = () => {
    setExceeded(window.innerWidth <= breakpoint);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return exceeded;
};
