import { FC, memo } from 'react';

import { Option } from 'interfaces';
import { InputElementBase } from '../../models/input-element-base';
import { Error } from 'components';

interface SelectInputProps extends InputElementBase {
  options: Option[];
  selectedValue: string | number;
}

export const SelectInput: FC<SelectInputProps> = memo(
  ({
    name,
    wrapperClassname = '',
    className,
    options,
    onChange,
    selectedValue,
    required,
    disabled,
    error,
    label,
    labelClass,
  }) => {
    return (
      <div className={`${wrapperClassname} mb-2`}>
        <div className={`focus-label ${error ? 'error' : ''}`}>
          {label && (
            <label
              htmlFor={name}
              className={`form-label ${labelClass} ${
                required ? 'required' : ''
              }`}
            >
              {label}
            </label>
          )}
          <select
            name={name}
            data-testid={name}
            required={!!required}
            disabled={!!disabled}
            value={selectedValue}
            onChange={onChange}
            defaultValue={selectedValue ?? ''}
            className={`form-select ${className}`}
          >
            {options.map((option) => {
              return (
                <option
                  key={option.name}
                  value={option.value}
                  hidden={!option.value}
                  disabled={!option.value}
                >
                  {option.name}
                </option>
              );
            })}
          </select>
          {error && <Error message={error} />}
        </div>
      </div>
    );
  },
);
