import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import { Constants } from '../constants';
import { BrowserName, DeviceType, OperatingSystemName } from 'enum';

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const capitalizeFirstLetters = (str: string) =>
  str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

export const toggleBodyOverflow = () => {
  const body = document.querySelector('body');
  return body?.classList.toggle('overflow-hidden');
};

export const toJSONLocal = (date: Date) => {
  const local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 16).replace('T', ' ');
};

export const formatDate = (date: Date) => date.toJSON().slice(0, 10);

export const getBrowserName = (userAgent: string) => {
  let defaultBrowserName = BrowserName.Chrome;

  Array.from(Constants.BROWSER_NAME_MAP.keys()).forEach((key: BrowserName) => {
    const includeKey = userAgent.includes(
      Constants.BROWSER_NAME_MAP.get(key) as string,
    );
    if (key === BrowserName.Safari) {
      if (includeKey && !userAgent.includes(BrowserName.Chrome)) {
        defaultBrowserName = BrowserName.Safari;
      }
    } else if (includeKey) {
      defaultBrowserName = key;
    }
  });

  return defaultBrowserName;
};

export const getOperatingSystem = (userAgent: string) => {
  let defaultOperatingSystem = OperatingSystemName.Windows;

  Array.from(Constants.OPERATING_SYSTEM_NAME_MAP.keys()).forEach(
    (key: OperatingSystemName) => {
      if (
        userAgent.includes(
          Constants.OPERATING_SYSTEM_NAME_MAP.get(key) as string,
        )
      ) {
        defaultOperatingSystem = key;
      }
    },
  );

  return defaultOperatingSystem;
};

export const getDeviceType = (userAgent: string) => {
  let defaultDeviceType = DeviceType.Desktop;

  if (userAgent.includes(DeviceType.Mobile)) {
    defaultDeviceType = DeviceType.Mobile;
  }

  return defaultDeviceType;
};

export const createCsvFile = (
  data: { [key: string]: string }[],
  headers: string[],
) => {
  const csvRows = [];

  csvRows.push(headers.join(','));
  data.forEach((item: { [key: string]: string }) => {
    const values = Object.values(item).join(',');
    csvRows.push(values);
  });

  return csvRows.join('\n');
};

export const downloadCsvFile = (data: BlobPart, fileName: string) => {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.setAttribute('href', url);

  link.setAttribute('download', `${fileName}.csv`);

  link.click();
};

export const scrollContent = (index: number, offsetHeight: number) => {
  const element = document?.querySelector(`#item${index}`) as HTMLElement;
  window.scrollTo({
    top: element?.offsetTop - offsetHeight,
    behavior: 'smooth',
  });
};

export const getTodayDate = () => {
  return new Date().toJSON().slice(0, 10).split('-').reverse().join('/');
};

export const onDownloadPdf = async (
  downLoadData: HTMLElement,
  fileName: string,
) => {
  html2canvas(downLoadData, {
    onclone(documentClone) {
      documentClone.querySelector('button[name="download"]')?.remove();
    },
  }).then((canvas) => {
    const imgWidth = 208;
    const pageHeight = 295;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;
    heightLeft -= pageHeight;
    const doc = new jsPDF('p', 'mm');

    doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      heightLeft -= pageHeight;
    }

    doc.save(`${fileName}.pdf`);
  });
};
