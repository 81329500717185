import { FC, memo } from 'react';

import { InputElementBase } from '../../models/input-element-base';
import { Divider, Error, Icon, Tooltip } from 'components';
import { DividerType, IconSize, TooltipDirection } from 'enum';

import styles from './Switch.module.scss';

export const Switch: FC<InputElementBase> = memo(
  ({
    checked,
    tabIndex,
    alt,
    disabled,
    type,
    wrapperClassname,
    className,
    withDivider,
    tooltip,
    tooltipMessage,
    tooltipDirection,
    tooltipDelay,
    label,
    labelClass,
    name,
    onChange,
    readOnly,
    required,
    error,
    labelPosition,
    cardClass,
  }) => {
    return (
      <>
        {withDivider && <Divider type={DividerType.Line} className="my-3" />}
        <div className={`my-3 ${wrapperClassname ?? ''}`}>
          <div
            className={`${cardClass} form-check form-switch focus-label ${
              error ? 'error' : ''
            }`}
          >
            <input
              id={name}
              className={`form-check-input ${className} ${styles.input}`}
              alt={alt}
              aria-label={name}
              data-testid={name}
              tabIndex={tabIndex}
              type={type}
              name={name}
              onChange={onChange}
              disabled={disabled}
              readOnly={readOnly}
              required={required}
              checked={checked}
              role="switch"
            />
            <label
              htmlFor={name}
              className={`text-break form-check-label open-sans-medium ${
                styles.label
              } ${labelClass ?? ''} ${labelPosition} ${
                required ? 'required' : ''
              }`}
            >
              {label}
            </label>
            {tooltip && (
              <Tooltip
                content={tooltipMessage}
                delay={tooltipDelay}
                direction={tooltipDirection || TooltipDirection.AUTO}
              >
                <Icon size={IconSize.SIZE_12} name="icon-dgpass-info" />
              </Tooltip>
            )}
          </div>
          {error && <Error message={error} />}
        </div>
      </>
    );
  },
);
