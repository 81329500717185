import { FC, useMemo, memo } from 'react';

import { Error } from 'components';
import { InputElementBase } from '../../models/input-element-base';

export const DatePicker: FC<InputElementBase> = memo(
  ({
    type,
    wrapperClassname,
    className = '',
    disabled,
    label,
    labelClass,
    name,
    onChange,
    placeholder,
    readOnly,
    required,
    value = '',
    error,
  }) => {
    const getCurrentDate = useMemo(() => new Date().toJSON().slice(0, 10), []);
    return (
      <div className={`${wrapperClassname} mb-2`}>
        <div className={`focus-label ${error ? 'error' : ''}`}>
          <label
            htmlFor={name}
            className={`form-label ${labelClass} ${required ? 'required' : ''}`}
          >
            {label}
          </label>
          <input
            id={name}
            className={`form-control ${className} ${!value ? 'empty' : ''}`}
            aria-label={name}
            data-testid={name}
            tabIndex={0}
            type={type}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            max={getCurrentDate}
            disabled={disabled}
            readOnly={readOnly}
            required={required}
          />
        </div>
        {error && <Error message={error} />}
      </div>
    );
  },
);
