import { memo, FC } from 'react';

import { AlignIcon } from 'enum';

import styles from './Icon.module.scss';

interface IconProps {
  name: string;
  className?: string;
  color?: string;
  size?: string;
  align?: AlignIcon;
  onClick?: () => void;
}

export const Icon: FC<IconProps> = memo(
  ({ name, className, color, size, align, onClick }) => {
    const styleIcon = {
      color,
      fontSize: size,
    };
    return (
      <i
        onClick={onClick}
        style={styleIcon}
        className={`${styles.info} ${name} ${className} ${
          align ? styles[align] : ''
        }`}
      ></i>
    );
  },
);
